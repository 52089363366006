import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import config from '../../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const INFORMES_URL = `https://${config.BACKEND}:3000/informes`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;
const USER_URL = `https://${config.BACKEND}:3000/users/`;

const columns = [
    { id: 'tipo', label: 'tipo', minWidth: 170 },
    { id: 'profesional', label: 'profesional', minWidth: 100 },
    {
        id: 'paciente',
        label: 'nombre del paciente',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'fecha',
        label: 'fecha(dd-mm-yyyy)',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'actions',
        label: '',
        minWidth: 50,
        align: 'right',
    }
];

function CapacidadFuncionalTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [reports, setReports] = useState([]);
    const [filterPaciente, setFilterPaciente] = useState('');
    const [filterProfesional, setFilterProfesional] = useState('');
    const [sortDate, setSortDate] = useState(null); // null, 'asc', 'desc'

    useEffect(() => {
        axios.get(INFORMES_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data.filter(informe => informe.capacidadId !== null); // Filtra solo informes de tipo "Capacidad Funcional"
                formatData(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const formatData = (data) => {
        const formattedData = data.map(informe => {
            let tipo = 'Capacidad Funcional';
            let idTipo = informe.capacidadId;

            // Formateo de la fecha dentro del mapeo
            const formattedDate = new Date(informe.createdAt);
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
            const year = formattedDate.getFullYear();
            const fecha = `${day}-${month}-${year}`;

            return {
                id: informe.id,
                idTipo: idTipo,
                tipo: tipo,
                pacienteId: informe.pacienteId,
                userId: informe.userId,
                fecha: fecha,
                fechaSort: formattedDate
            };
        });

        // Fetch data for each report
        Promise.all(formattedData.map(report => axios.get(PACIENTE_URL + report.pacienteId, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })))
            .then(responses => {
                formattedData.forEach((report, index) => {
                    const paciente = responses[index].data;
                    report.paciente = `${paciente.firstname} ${paciente.lastname}`;
                });

                // Fetch data for each user
                return Promise.all(formattedData.map(report => axios.get(USER_URL + report.userId, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })));
            })
            .then(responses => {
                formattedData.forEach((report, index) => {
                    const user = responses[index].data;
                    report.profesional = `${user.firstname} ${user.lastname}`;
                });

                setReports(formattedData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSortDate = () => {
        if (sortDate === 'asc') {
            setSortDate('desc');
        } else {
            setSortDate('asc');
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'paciente':
                setFilterPaciente(value);
                break;
            case 'profesional':
                setFilterProfesional(value);
                break;
            default:
                break;
        }
    };

    const handleResetFilters = () => {
        setFilterPaciente('');
        setFilterProfesional('');
        setSortDate(null);
    };

    const filteredReports = reports
        .filter(report => filterPaciente === '' || report.paciente.toLowerCase().includes(filterPaciente.toLowerCase()))
        .filter(report => filterProfesional === '' || report.profesional.toLowerCase().includes(filterProfesional.toLowerCase()))
        .sort((a, b) => {
            if (sortDate === 'asc') {
                return a.fechaSort - b.fechaSort;
            } else if (sortDate === 'desc') {
                return b.fechaSort - a.fechaSort;
            }
            return 0;
        });

    return (
        <>
            <Typography variant="h6" component="h2" gutterBottom color='#0D6AEE'>
                Informes de Capacidad Funcional
            </Typography>
            <div>
                <TextField
                    name="paciente"
                    label="Filtrar por Paciente"
                    value={filterPaciente}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <TextField
                    name="profesional"
                    label="Filtrar por Profesional"
                    value={filterProfesional}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <Button variant="contained" onClick={handleSortDate} style={{ marginRight: 16 }}>
                    Ordenar por fecha {sortDate === 'asc' ? '⬆️' : '⬇️'}
                </Button>
                <Button variant="contained" onClick={handleResetFilters}>
                    Resetear Filtros
                </Button>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textDecoration: 'underline', color: '#0D6AEE' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredReports.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No hay informes registrados
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredReports
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell align="right">
                                                <IconButton color="primary" onClick={() => { window.location.href = `/ver-Capacidad-Funcional/${row.idTipo}/${row.pacienteId}`; }}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredReports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default CapacidadFuncionalTable;
