import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import config from '../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const INFORMES_PACIENTE_URL = `https://${config.BACKEND}:3000/informes/`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;
const PAC_URL = `https://${config.BACKEND}:3000/pacientes/`;
const USER_URL = `https://${config.BACKEND}:3000/users/`;

const columns = [
    { id: 'tipo', label: 'Tipo', minWidth: 170 },
    { id: 'profesional', label: 'Profesional', minWidth: 100 },
    {
        id: 'paciente',
        label: 'Nombre del Paciente',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'fecha',
        label: 'Fecha (dd-mm-yyyy)',
        minWidth: 170,
        align: 'right',
    },
    { id: 'actions', label: '', minWidth: 50, align: 'right' }
];

function ReportsTable({ selectedPaciente }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [reports, setReports] = useState([]);
    const [searchTipo, setSearchTipo] = useState('');
    const [searchProfesional, setSearchProfesional] = useState('');
    const [sortDate, setSortDate] = useState(null); // null, 'asc', 'desc'

    useEffect(() => {
        if (selectedPaciente !== '') {
            axios.get(PAC_URL + selectedPaciente, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const paciente = response.data;
                    return axios.get(INFORMES_PACIENTE_URL + paciente.id, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                })
                .then(response => {
                    const data = response.data;
                    formatData(data);
                })
                .catch(error => console.error('Error fetching data:', error));
        } else {
            setReports([]);
        }
    }, [selectedPaciente]);

    const formatData = (data) => {
        const formattedData = data.map(informe => {
            let tipo = '';
            let idTipo;

            if (informe.otrosId !== null) {
                idTipo = informe.otrosId;
                tipo = 'Otros';
            }
            else if (informe.medidasId !== null) {
                idTipo = informe.medidasId;
                tipo = 'Antropometría';
            }
            else if (informe.valoracionId !== null) {
                idTipo = informe.valoracionId;
                tipo = 'Valoración Funcional';
            }
            else if (informe.capacidadId !== null) {
                idTipo = informe.capacidadId;
                tipo = 'Capacidad Funcional';
            }
            else if (informe.tensionId !== null) {
                idTipo = informe.tensionId;
                tipo = 'Tensión Arterial';
            }

            const formattedDate = new Date(informe.createdAt);
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const year = formattedDate.getFullYear();
            const fecha = `${day}-${month}-${year}`;

            return {
                id: informe.id,
                idTipo: idTipo,
                tipo: tipo,
                pacienteId: informe.pacienteId,
                userId: informe.userId,
                fecha: fecha,
                fechaSort: formattedDate
            };
        });

        Promise.all(formattedData.map(report => axios.get(PACIENTE_URL + report.pacienteId, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })))
            .then(responses => {
                formattedData.forEach((report, index) => {
                    const paciente = responses[index].data;
                    report.paciente = `${paciente.firstname} ${paciente.lastname}`;
                });

                return Promise.all(formattedData.map(report => axios.get(USER_URL + report.userId, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })));
            })
            .then(responses => {
                formattedData.forEach((report, index) => {
                    const user = responses[index].data;
                    report.profesional = `${user.firstname} ${user.lastname}`;
                });

                setReports(formattedData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const resetFilters = () => {
        setSearchTipo('');
        setSearchProfesional('');
        setSortDate(null);
    };

    const handleSortDate = () => {
        if (sortDate === 'asc') {
            setSortDate('desc');
        } else {
            setSortDate('asc');
        }
    };

    const filteredReports = reports
        .filter(report => {
            return (
                (searchTipo === '' || report.tipo.toLowerCase().includes(searchTipo.toLowerCase())) &&
                (searchProfesional === '' || report.profesional.toLowerCase().includes(searchProfesional.toLowerCase()))
            );
        });

    const sortedReports = [...filteredReports].sort((a, b) => {
        if (sortDate === 'asc') {
            return a.fechaSort - b.fechaSort;
        } else if (sortDate === 'desc') {
            return b.fechaSort - a.fechaSort;
        }
        return 0;
    });

    return (
        <>
            <Typography variant="h6" component="h2" gutterBottom color='#0D6AEE'>
                Lista de Informes
            </Typography>
            <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
                <TextField
                    label="Buscar por tipo"
                    variant="outlined"
                    value={searchTipo}
                    onChange={(e) => setSearchTipo(e.target.value)}
                    style={{ marginRight: '20px' }}
                />
                <TextField
                    label="Buscar por profesional"
                    variant="outlined"
                    value={searchProfesional}
                    onChange={(e) => setSearchProfesional(e.target.value)}
                    style={{ marginRight: '20px' }}
                />
                <Button onClick={handleSortDate}>
                    Ordenar por fecha {sortDate === 'asc' ? '⬆️' : '⬇️'}
                </Button>
                <Button variant="contained" onClick={resetFilters} style={{ marginLeft: '20px' }}>
                    Resetear Filtros
                </Button>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textDecoration: 'underline', color: '#0D6AEE' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedReports.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No hay informes registrados de este paciente
                                    </TableCell>
                                </TableRow>
                            ) : (
                                sortedReports
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'actions' ? (
                                                            <IconButton
                                                                aria-label="view"
                                                                onClick={() => {
                                                                    if (row.tipo === 'Valoración Funcional') {
                                                                        window.location.href = `/ver-Valoracion-Funcional/${row.idTipo}/${row.pacienteId}`;
                                                                    }
                                                                    else if (row.tipo === 'Otros') {
                                                                        window.location.href = `/ver-Otros/${row.idTipo}/${row.pacienteId}`;
                                                                    }
                                                                    else if (row.tipo === 'Capacidad Funcional') {
                                                                        window.location.href = `/ver-Capacidad-Funcional/${row.idTipo}/${row.pacienteId}`;
                                                                    }
                                                                    else if (row.tipo === 'Antropometría') {
                                                                        window.location.href = `/ver-Antropometria/${row.idTipo}`;
                                                                    }
                                                                    else if (row.tipo === 'Tensión Arterial') {
                                                                        window.location.href = `/ver-Tension-Arterial/${row.idTipo}/${row.pacienteId}`;
                                                                    }
                                                                }}
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        ) : (
                                                            value
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Resultados por página:"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={sortedReports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default ReportsTable;
