import React from 'react';
import { TextField, Typography, Box, AppBar, Toolbar, Button, Checkbox, FormControl, FormControlLabel, Select, MenuItem, Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/entrevistas/";
const PACIENTE_URL = "https://" + config.BACKEND + ":3000/pacientes/";

const Entrevista = ({codigo}) => {
    const [codHistorico] = React.useState(codigo);
    const [fumador, setFumador] = React.useState('');
    const [tiempoFumador, setTiempoFumador] = React.useState('');
    const [aniosDeshabilitacion, setAniosDeshabilitacion] = React.useState('');
    const [alcohol, setAlcohol] = React.useState('');
    const [tiempoAlcohol, setTiempoAlcohol] = React.useState('');
    const [aniosDeshabilitacionAlcohol, setAniosDeshabilitacionAlcohol] = React.useState('');
    const [caidas12Meses, setCaidas12Meses] = React.useState(false);
    const [numCaidas, setNumCaidas] = React.useState('');
    const [ayudaLocomocion, setAyudaLocomocion] = React.useState(false);
    const [protesisArticulares, setProtesisArticulares] = React.useState(false);
    const [protesis, setProtesis] = React.useState({
        rodilla: false,
        cadera: false,
        hombro: false,
        manoDedos: false,
        otros: ' '
    });
    const [vertigos, setVertigos] = React.useState(false);
    const [mareosAndar, setMareosAndar] = React.useState(false);
    const [cardiovascular, setCardiovascular] = React.useState(false);
    const [cardioOptions, setCardioOptions] = React.useState({
        hipertension: false,
        insuficienciaCardiaca: false,
        accCerebrovascular: false,
        anginaPecho: false,
        infarto: false,
        trombosis: false,
        otros: ' '
    });
    const [respiratorio, setRespiratorio] = React.useState(false);
    const [respOptions, setRespOptions] = React.useState({
        asma: false,
        epoc: false,
        otros: ' '
    });
    const [genitoUrinario, setGenitoUrinario] = React.useState(false);
    const [genitoOptions, setGenitoOptions] = React.useState({
        infeccionUrinaria: false,
        calculosRenales: false,
        insuficienciaRenal: false,
        incontinenciaUrinaria: false,
        prostata: false,
        otros: ' '
    });
    const [endocrino, setEndocrino] = React.useState(false);
    const [endocrinoOptions, setEndocrinoOptions] = React.useState({
        diabetes: false,
        hipotiroidismo: false,
        hipercolesterolemia: false,
        osteoporosis: false,
        otros: ' '
    });
    const [digestivo, setDigestivo] = React.useState(false);
    const [digestOptions, setDigestOptions] = React.useState({
        herniaHiato: false,
        reflujo: false,
        pancreatitis: false,
        enfermedadHepatica: false,
        diverticulitis: false,
        otros: ' '
    });
    const [musculoEsqueletico, setMusculoEsqueletico] = React.useState(false);
    const [musculoOptions, setMusculoOptions] = React.useState({
        pieTobillo: false,
        rodilla: false,
        cadera: false,
        lumbar: false,
        dorsal: false,
        cervical: false,
        cabeza: false,
        hombro: false,
        codo: false,
        manoMunieca: false
    });
    const [nervioso, setNervioso] = React.useState(false);
    const [nervOptions, setNervOptions] = React.useState({
        depresion: false,
        ansiedad: false,
        esclerosisMultiple: false,
        parkinson: false,
        alzheimer: false,
        deterioroCognitivo: false,
        neuropatiaPeriferica: false,
        otros: ' '
    });
    const [sentidos, setSentidos] = React.useState(false);
    const [sentidosOptions, setSentidosOptions] = React.useState({
        hipoacusia: false,
        tinitus: false,
        perdidaVision: false
    });
    const [medicacion, setMedicacion] = React.useState(false);
    const [medOptions, setMedOptions] = React.useState({
        antihipertensivos: false,
        antidiabeticos: false,
        estatinas: false,
        anticoagulantes: false,
        tiroides: false,
        diuretico: false,
        analgesicos: false,
        antidepresivos: false,
        ansioliticos: false,
        antiosteoporoticos: false,
        inhalador: false,
        protectorEstomago: false,
        otros: ' '
    });
    const [venredi, setVenredi] = React.useState(false);
    const [terapiaOcupacional, setTerapiaOcupacional] = React.useState(false);
    const [datosInteres, setDatosInteres] = React.useState('');

    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const guardarDatos = () => {
        let paciente;
        let pacienteId;
        let numFumador = 0;
        let numAlcohol = 0;

        if (fumador === "No") {
            numFumador = 1
        } else if (fumador === "Sí") {
            numFumador = 2;
        } else {
            numFumador = 3;
        }

        if (alcohol === "No") {
            numAlcohol = 1;
        } else if (alcohol === "Sí") {
            numAlcohol = 2;
        } else {
            numAlcohol = 3;
        }

        axios.get(PACIENTE_URL + codHistorico, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                paciente = response.data;

                pacienteId = paciente.id.toString();

                axios.post(API_URL + pacienteId, {
                    fumador: numFumador,
                    tiempoFumador: parseInt(tiempoFumador),
                    aniosDeshabilitacion: parseInt(aniosDeshabilitacion),
                    alcohol: numAlcohol,
                    tiempoAlcohol: parseInt(tiempoAlcohol),
                    aniosDeshabilitacionAlcohol: aniosDeshabilitacionAlcohol,
                    caidas12Meses: caidas12Meses,
                    numCaidas: parseInt(numCaidas),
                    ayudaLocomocion: ayudaLocomocion,
                    protesisArticulares: protesisArticulares,
                    protesisRodilla: protesis.rodilla,
                    protesisCadera: protesis.cadera,
                    protesisHombro: protesis.hombro,
                    protesisManoDedos: protesis.manoDedos,
                    protesisOtros: protesis.otros,
                    vertigos: vertigos,
                    mareosAndar: mareosAndar,
                    cardiovascular: cardiovascular,
                    hipertension: cardioOptions.hipertension,
                    insuficienciaCardiaca: cardioOptions.insuficienciaCardiaca,
                    accCerebrovascular: cardioOptions.accCerebrovascular,
                    anginaPecho: cardioOptions.anginaPecho,
                    infarto: cardioOptions.infarto,
                    trombosis: cardioOptions.trombosis,
                    otrosCardiovascular: cardioOptions.otros,
                    respiratorio: respiratorio,
                    asma: respOptions.asma,
                    epoc: respOptions.epoc,
                    otroRespiratorio: respOptions.otros,
                    genitoUrinario: genitoUrinario,
                    infeccionUrinaria: genitoOptions.infeccionUrinaria,
                    calculosRenales: genitoOptions.calculosRenales,
                    insuficienciaRenal: genitoOptions.insuficienciaRenal,
                    incotinenciaUrinaria: genitoOptions.incontinenciaUrinaria,
                    prostata: genitoOptions.prostata,
                    otrosGenitoUrinario: genitoOptions.otros,
                    endocrino: endocrino,
                    diabetes: endocrinoOptions.diabetes,
                    hipotiroidismo: endocrinoOptions.hipotiroidismo,
                    hipercolesterolemia: endocrinoOptions.hipercolesterolemia,
                    osteoporosis: endocrinoOptions.osteoporosis,
                    otrosEndocrino: endocrinoOptions.otros,
                    digestivo: digestivo,
                    herniaHiato: digestOptions.herniaHiato,
                    reflujo: digestOptions.reflujo,
                    pancreatitis: digestOptions.pancreatitis,
                    enfermedadHepatica: digestOptions.enfermedadHepatica,
                    diverticulitis: digestOptions.diverticulitis,
                    otrosDigestivo: digestOptions.otros,
                    musculoEsqueletico: musculoEsqueletico,
                    pieTobillo: musculoOptions.pieTobillo,
                    rodilla: musculoOptions.rodilla,
                    cadera: musculoOptions.cadera,
                    lumbar: musculoOptions.lumbar,
                    dorsal: musculoOptions.dorsal,
                    cervical: musculoOptions.cervical,
                    cabeza: musculoOptions.cabeza,
                    hombro: musculoOptions.hombro,
                    codo: musculoOptions.codo,
                    mano_munieca: musculoOptions.manoMunieca,
                    nervioso: nervioso,
                    depresion: nervOptions.depresion,
                    ansiedad: nervOptions.ansiedad,
                    esclerosisMultiple: nervOptions.esclerosisMultiple,
                    parkinson: nervOptions.parkinson,
                    alzheimer: nervOptions.alzheimer,
                    deterioroCognitivo: nervOptions.deterioroCognitivo,
                    neuropatiaPeriferica: nervOptions.neuropatiaPeriferica,
                    otrosNervioso: nervOptions.otrosNervioso,
                    sentidos: sentidos,
                    hipoacusia: sentidosOptions.hipoacusia,
                    tinitus: sentidosOptions.tinitus,
                    perdidaVision: sentidosOptions.perdidaVision,
                    datosInteres: datosInteres,
                    medicacion: medicacion,
                    antihipertensivos: medOptions.antihipertensivos,
                    antidiabeticos: medOptions.antidiabeticos,
                    estatinas: medOptions.estatinas,
                    anticoagulantes: medOptions.anticoagulantes,
                    tiroides: medOptions.tiroides,
                    diuretico: medOptions.diuretico,
                    analgesicos: medOptions.analgesicos,
                    antidepresivos: medOptions.antidepresivos,
                    ansioliticos: medOptions.ansioliticos,
                    antiosteoporoticos: medOptions.antiosteoporoticos,
                    inhalador: medOptions.inhalador,
                    protectorEstomago: medOptions.protectorEstomago,
                    otrosMedicacion: medOptions.otrosMedicacion,
                    venredi: venredi,
                    terapiaOcupacional: terapiaOcupacional,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    setOpenGood(true);
                    window.location.href = "/home";
                })
            })
            .catch(error => {
                setOpenError(true);
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img
                            src={logoUsal}
                            alt="Logo Izquierda"
                            style={{ height: '100px', width: 'auto', marginRight: 'auto' }}
                        />
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}
                        >
                            ENTREVISTA CLÍNICA
                        </Typography>
                        <img
                            src={logoCiencias}
                            alt="Logo Derecha"
                            style={{ height: '100px', width: 'auto', marginLeft: 'auto' }}
                        />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Código del Paciente"
                            required
                            variant="outlined"
                            sx={{ width: '25%' }}
                            value={codHistorico}
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" gutterBottom>Fumador</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Select
                                    value={fumador}
                                    onChange={(e) => setFumador(e.target.value)}
                                    sx={{ flexGrow: 1, width: '25%' }}
                                >
                                    <MenuItem value="Si">Sí</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                    <MenuItem value="Fue fumador">Fue fumador</MenuItem>
                                </Select>
                                {fumador === 'Fue fumador' && (
                                    <>
                                        <TextField
                                            label="Tiempo Fumador (años)"
                                            variant="outlined"
                                            value={tiempoFumador}
                                            onChange={(e) => setTiempoFumador(e.target.value)}
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <TextField
                                            label="Años de Deshabilitación por Fumar"
                                            variant="outlined"
                                            value={aniosDeshabilitacion}
                                            onChange={(e) => setAniosDeshabilitacion(e.target.value)}
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </>
                                )}
                            </Box>
                        </FormControl>
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <Typography variant="subtitle1" gutterBottom>Consumidor de Alcohol</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Select
                                    value={alcohol}
                                    onChange={(e) => setAlcohol(e.target.value)}
                                    sx={{ flexGrow: 1, width: '25%' }}
                                >
                                    <MenuItem value="Si">Sí</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                    <MenuItem value="Consumía alcohol">Consumía alcohol</MenuItem>
                                </Select>
                                {alcohol === 'Consumía alcohol' && (
                                    <>
                                        <TextField
                                            label="Tiempo Consumidor de Alcohol (años)"
                                            variant="outlined"
                                            value={tiempoAlcohol}
                                            onChange={(e) => setTiempoAlcohol(e.target.value)}
                                            sx={{ flexGrow: 1 }}
                                        />
                                        <TextField
                                            label="Años de Deshabilitación por Alcohol"
                                            variant="outlined"
                                            value={aniosDeshabilitacionAlcohol}
                                            onChange={(e) => setAniosDeshabilitacionAlcohol(e.target.value)}
                                            sx={{ flexGrow: 1 }}
                                        />
                                    </>
                                )}
                            </Box>
                        </FormControl>
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={caidas12Meses} onChange={(e) => setCaidas12Meses(e.target.checked)} />}
                            label="¿Ha sufrido caídas en los últimos 12 meses?"
                        />
                        {caidas12Meses && (
                            <TextField
                                label="Número de caídas"
                                variant="outlined"
                                sx={{ width: '25%', marginBottom: 4 }}
                                value={numCaidas}
                                onChange={(e) => setNumCaidas(e.target.value)}
                            />
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={ayudaLocomocion} onChange={(e) => setAyudaLocomocion(e.target.checked)} />}
                            label="¿Utiliza algún tipo de ayuda para la locomoción?"
                        />
                    </Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={protesisArticulares} onChange={(e) => setProtesisArticulares(e.target.checked)} />}
                            label="¿Utiliza prótesis articulares?"
                        />
                        {protesisArticulares && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={protesis.rodilla} onChange={(e) => setProtesis({ ...protesis, rodilla: e.target.checked })} />}
                                    label="Rodilla"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={protesis.cadera} onChange={(e) => setProtesis({ ...protesis, cadera: e.target.checked })} />}
                                    label="Cadera"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={protesis.hombro} onChange={(e) => setProtesis({ ...protesis, hombro: e.target.checked })} />}
                                    label="Hombro"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={protesis.manoDedos} onChange={(e) => setProtesis({ ...protesis, manoDedos: e.target.checked })} />}
                                    label="Mano/Dedos"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={protesis.otros}
                                    onChange={(e) => setProtesis({ ...protesis, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={vertigos} onChange={(e) => setVertigos(e.target.checked)} />}
                            label="¿Sufre de vértigos?"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={mareosAndar} onChange={(e) => setMareosAndar(e.target.checked)} />}
                            label="¿Sufre de mareos al andar?"
                        />
                    </Box>


                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={cardiovascular} onChange={(e) => setCardiovascular(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad cardiovascular?"
                        />
                        {cardiovascular && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={cardioOptions.hipertension} onChange={(e) => setCardioOptions({ ...cardioOptions, hipertension: e.target.checked })} />}
                                    label="Hipertensión"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cardioOptions.insuficienciaCardiaca} onChange={(e) => setCardioOptions({ ...cardioOptions, insuficienciaCardiaca: e.target.checked })} />}
                                    label="Insuficiencia Cardíaca"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cardioOptions.accCerebrovascular} onChange={(e) => setCardioOptions({ ...cardioOptions, accCerebrovascular: e.target.checked })} />}
                                    label="Accidente Cerebrovascular"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cardioOptions.anginaPecho} onChange={(e) => setCardioOptions({ ...cardioOptions, anginaPecho: e.target.checked })} />}
                                    label="Angina de Pecho"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cardioOptions.infarto} onChange={(e) => setCardioOptions({ ...cardioOptions, infarto: e.target.checked })} />}
                                    label="Infarto"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={cardioOptions.trombosis} onChange={(e) => setCardioOptions({ ...cardioOptions, trombosis: e.target.checked })} />}
                                    label="Trombosis"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={cardioOptions.otros}
                                    onChange={(e) => setCardioOptions({ ...cardioOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={respiratorio} onChange={(e) => setRespiratorio(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad respiratoria?"
                        />
                        {respiratorio && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={respOptions.asma} onChange={(e) => setRespOptions({ ...respOptions, asma: e.target.checked })} />}
                                    label="Asma"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={respOptions.epoc} onChange={(e) => setRespOptions({ ...respOptions, epoc: e.target.checked })} />}
                                    label="EPOC"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={respOptions.otros}
                                    onChange={(e) => setRespOptions({ ...respOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={genitoUrinario} onChange={(e) => setGenitoUrinario(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad genito-urinaria?"
                        />
                        {genitoUrinario && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={genitoOptions.infeccionUrinaria} onChange={(e) => setGenitoOptions({ ...genitoOptions, infeccionUrinaria: e.target.checked })} />}
                                    label="Infección Urinaria"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={genitoOptions.calculosRenales} onChange={(e) => setGenitoOptions({ ...genitoOptions, calculosRenales: e.target.checked })} />}
                                    label="Cálculos Renales"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={genitoOptions.insuficienciaRenal} onChange={(e) => setGenitoOptions({ ...genitoOptions, insuficienciaRenal: e.target.checked })} />}
                                    label="Insuficiencia Renal"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={genitoOptions.incontinenciaUrinaria} onChange={(e) => setGenitoOptions({ ...genitoOptions, incontinenciaUrinaria: e.target.checked })} />}
                                    label="Incontinencia Urinaria"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={genitoOptions.prostata} onChange={(e) => setGenitoOptions({ ...genitoOptions, prostata: e.target.checked })} />}
                                    label="Problemas de Próstata"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={genitoOptions.otros}
                                    onChange={(e) => setGenitoOptions({ ...genitoOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={digestivo} onChange={(e) => setDigestivo(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad digestiva?"
                        />
                        {digestivo && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={digestOptions.herniaHiato} onChange={(e) => setDigestOptions({ ...digestOptions, herniaHiato: e.target.checked })} />}
                                    label="Hernia de hiato"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={digestOptions.reflujo} onChange={(e) => setDigestOptions({ ...digestOptions, reflujo: e.target.checked })} />}
                                    label="Reflujo"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={digestOptions.pancreatitis} onChange={(e) => setDigestOptions({ ...digestOptions, pancreatitis: e.target.checked })} />}
                                    label="Pancreatitis"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={digestOptions.enfermedadHepatica} onChange={(e) => setDigestOptions({ ...digestOptions, enfermedadHepatica: e.target.checked })} />}
                                    label="Enfermedad hepática"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={digestOptions.diverticulitis} onChange={(e) => setDigestOptions({ ...digestOptions, diverticulitis: e.target.checked })} />}
                                    label="Diverticulitis"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={digestOptions.otros}
                                    onChange={(e) => setDigestOptions({ ...digestOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={endocrino} onChange={(e) => setEndocrino(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad endocrina?"
                        />
                        {endocrino && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={endocrinoOptions.diabetes} onChange={(e) => setEndocrinoOptions({ ...endocrinoOptions, diabetes: e.target.checked })} />}
                                    label="Diabetes"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={endocrinoOptions.hipotiroidismo} onChange={(e) => setEndocrinoOptions({ ...endocrinoOptions, hipotiroidismo: e.target.checked })} />}
                                    label="Hipotiroidismo"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={endocrinoOptions.hipercolesterolemia} onChange={(e) => setEndocrinoOptions({ ...endocrinoOptions, hipercolesterolemia: e.target.checked })} />}
                                    label="Hipercolesterolemia"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={endocrinoOptions.osteoporosis} onChange={(e) => setEndocrinoOptions({ ...endocrinoOptions, osteoporosis: e.target.checked })} />}
                                    label="Osteoporosis"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={endocrinoOptions.otros}
                                    onChange={(e) => setEndocrinoOptions({ ...endocrinoOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={musculoEsqueletico} onChange={(e) => setMusculoEsqueletico(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad musculoesquelética?"
                        />
                        {musculoEsqueletico && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.pieTobillo} onChange={(e) => setMusculoOptions({ ...musculoOptions, pieTobillo: e.target.checked })} />}
                                    label="Pie y tobillo"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.rodilla} onChange={(e) => setMusculoOptions({ ...musculoOptions, rodilla: e.target.checked })} />}
                                    label="Rodilla"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.cadera} onChange={(e) => setMusculoOptions({ ...musculoOptions, cadera: e.target.checked })} />}
                                    label="Cadera"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.lumbar} onChange={(e) => setMusculoOptions({ ...musculoOptions, lumbar: e.target.checked })} />}
                                    label="Lumbar"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.dorsal} onChange={(e) => setMusculoOptions({ ...musculoOptions, dorsal: e.target.checked })} />}
                                    label="Dorsal"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.cervical} onChange={(e) => setMusculoOptions({ ...musculoOptions, cervical: e.target.checked })} />}
                                    label="Cervical"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.cabeza} onChange={(e) => setMusculoOptions({ ...musculoOptions, cabeza: e.target.checked })} />}
                                    label="Cabeza"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.hombro} onChange={(e) => setMusculoOptions({ ...musculoOptions, hombro: e.target.checked })} />}
                                    label="Hombro"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.codo} onChange={(e) => setMusculoOptions({ ...musculoOptions, codo: e.target.checked })} />}
                                    label="Codo"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={musculoOptions.manoMunieca} onChange={(e) => setMusculoOptions({ ...musculoOptions, manoMunieca: e.target.checked })} />}
                                    label="Mano y muñeca"
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={nervioso} onChange={(e) => setNervioso(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad del sistema nervioso?"
                        />
                        {nervioso && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.depresion} onChange={(e) => setNervOptions({ ...nervOptions, depresion: e.target.checked })} />}
                                    label="Depresión"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.ansiedad} onChange={(e) => setNervOptions({ ...nervOptions, ansiedad: e.target.checked })} />}
                                    label="Ansiedad"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.esclerosisMultiple} onChange={(e) => setNervOptions({ ...nervOptions, esclerosisMultiple: e.target.checked })} />}
                                    label="Esclerosis Múltiple"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.parkinson} onChange={(e) => setNervOptions({ ...nervOptions, parkinson: e.target.checked })} />}
                                    label="Parkinson"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.alzheimer} onChange={(e) => setNervOptions({ ...nervOptions, alzheimer: e.target.checked })} />}
                                    label="Alzheimer"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.deterioroCognitivo} onChange={(e) => setNervOptions({ ...nervOptions, deterioroCognitivo: e.target.checked })} />}
                                    label="Deterioro Cognitivo"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={nervOptions.neuropatiaPeriferica} onChange={(e) => setNervOptions({ ...nervOptions, neuropatiaPeriferica: e.target.checked })} />}
                                    label="Neuropatía Periférica"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={nervOptions.otros}
                                    onChange={(e) => setNervOptions({ ...nervOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={sentidos} onChange={(e) => setSentidos(e.target.checked)} />}
                            label="¿Tiene alguna enfermedad en el sistema sensorial?"
                        />
                        {sentidos && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={sentidosOptions.hipoacusia} onChange={(e) => setSentidosOptions({ ...sentidosOptions, hipoacusia: e.target.checked })} />}
                                    label="Hipocausia"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={sentidosOptions.tinitus} onChange={(e) => setSentidosOptions({ ...sentidosOptions, tinitus: e.target.checked })} />}
                                    label="Tinitus"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={sentidosOptions.perdidaVision} onChange={(e) => setSentidosOptions({ ...sentidosOptions, perdidaVision: e.target.checked })} />}
                                    label="Pérdida de visión"
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Datos de interés"
                            variant="outlined"
                            sx={{ width: '100%', marginBottom: 2 }}
                            value={datosInteres}
                            onChange={(e) => setDatosInteres(e.target.value)}
                        />
                    </Box>

                    <Box sx={{ marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={medicacion} onChange={(e) => setMedicacion(e.target.checked)} />}
                            label="¿Toma alguna medicación?"
                        />
                        {medicacion && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.antihipertensivos} onChange={(e) => setMedOptions({ ...medOptions, antihipertensivos: e.target.checked })} />}
                                    label="Antihipertensivos"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.antidiabeticos} onChange={(e) => setMedOptions({ ...medOptions, antidiabeticos: e.target.checked })} />}
                                    label="Antidiabeticos"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.estatinas} onChange={(e) => setMedOptions({ ...medOptions, estatinas: e.target.checked })} />}
                                    label="Estatinas o hipolipemiante"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.anticoagulantes} onChange={(e) => setMedOptions({ ...medOptions, anticoagulantes: e.target.checked })} />}
                                    label="Anticoagulantes"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.tiroides} onChange={(e) => setMedOptions({ ...medOptions, tiroides: e.target.checked })} />}
                                    label="Tto tiroides"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.diuretico} onChange={(e) => setMedOptions({ ...medOptions, diuretico: e.target.checked })} />}
                                    label="Diurético"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.analgesicos} onChange={(e) => setMedOptions({ ...medOptions, analgesicos: e.target.checked })} />}
                                    label="Analgésicos"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.antidepresivos} onChange={(e) => setMedOptions({ ...medOptions, antidepresivos: e.target.checked })} />}
                                    label="Antidepresivos"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.ansioliticos} onChange={(e) => setMedOptions({ ...medOptions, ansioliticos: e.target.checked })} />}
                                    label="Ansiolíticos"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.antiosteoporoticos} onChange={(e) => setMedOptions({ ...medOptions, antiosteoporoticos: e.target.checked })} />}
                                    label="Antiosteoporóticos"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.inhalador} onChange={(e) => setMedOptions({ ...medOptions, inhalador: e.target.checked })} />}
                                    label="Inhalador"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={medOptions.protectorEstomago} onChange={(e) => setMedOptions({ ...medOptions, protectorEstomago: e.target.checked })} />}
                                    label="Protector de estómago"
                                />
                                <TextField
                                    label="Otros (especificar)"
                                    variant="outlined"
                                    sx={{ width: '25%', marginBottom: 4 }}
                                    value={medOptions.otros}
                                    onChange={(e) => setMedOptions({ ...medOptions, otros: e.target.value })}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={venredi} onChange={(e) => setVenredi(e.target.checked)} />}
                            label="Venredi"
                            sx={{ marginRight: 4 }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={terapiaOcupacional} onChange={(e) => setTerapiaOcupacional(e.target.checked)} />}
                            label="Terapia Ocupacional"
                        />
                    </Box>

                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>Entrevista creada correctamente!</Alert>
                    </Snackbar>
                    <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                        <Alert severity="error" onClose={() => setOpenError(false)}>No hay pacientes con ese código.</Alert>
                    </Snackbar>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    <Button variant="contained" color="primary" onClick={guardarDatos}>
                        Guardar Datos
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Entrevista;
