import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MainRegister from './components/register/MainRegister';
import MainLogin from './components/login/MainLogin';
import HomeAdmin from './components/admin/home/Home';
import CrearUsuario from './components/admin/crearUsuario/ViewCrearUsuario';
import CrearPaciente from './components/admin/crearPaciente/ViewCrearPaciente';
import Home from './components/users/home/Home';
import CrearPacienteUser from './components/users/crearPaciente/CrearPaciente';
import Entrevista from './components/users/crearEntrevista/ViewEntrevista';
import CapacidadFuncional from './components/users/crearCapacidadFuncional/ViewCapacidadFuncional';
import Antropometria from './components/users/crearAntropometria/ViewAntropometria';
import TensionArterial from './components/users/crearTensionArterial/ViewTensionArterial';
import ValoracionFuncional from './components/users/crearValoracionFuncional/ViewValoracionFuncional';
import Otros from './components/users/crearOtros/ViewOtros';
import MainValoracionFuncional from './components/views/viewerValoracionFuncional/MainValoracionFuncional';
import MainTensionArterial from './components/views/viewerTensionArterial/MainTensionArterial';
import MainOtros from './components/views/viewerOtros/MainOtros';
import MainCapacidadFuncional from './components/views/viewerCapacidadFuncional/MainCapacidadFuncional';
import MainAntropometria from './components/views/viewerAntropometria/MainAntropometria';
import MainUser from './components/views/viewerUser/MainUser';
import MainAdmin from './components/views/viewerAdmin/MainAdmin';
import MainEntrevista from './components/views/viewerEntrevista/MainEntrevista';
import MainPaciente from './components/paciente/MainPaciente';
import TableEntrevista from './components/users/viewTables/entrevista/MainEntrevista';
import TableAntropometria from './components/users/viewTables/antropometria/MainAntropometria';
import TableCapacidadFuncional from './components/users/viewTables/capacidadFuncional/MainCapacidadFuncional';
import TableTensionArterial from './components/users/viewTables/tensionArterial/MainTensionArterial';
import TableValoracionFuncional from './components/users/viewTables/valoracionFuncional/MainValoracionFuncional';
import TableOtros from './components/users/viewTables/otros/MainOtros';
import ProtectedRoutes from './ProtectedRoutes';
import ProtectedRoutesAdmin from './ProtectedRoutesAdmin';

function App() {
  const [selectedPaciente, setSelectedPaciente] = React.useState('');

  return (
    <Routes>
      <Route path="/register" element={<MainRegister />} />
      <Route path="/login" element={<MainLogin />} />
      {/* Ruta por defecto */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route element={<ProtectedRoutesAdmin />}>
        <Route path="/homeAdmin" element={<HomeAdmin />} />
      </Route>
      <Route element={<ProtectedRoutesAdmin />}>
        <Route path="/crear-usuario" element={<CrearUsuario />} />
      </Route>
      <Route element={<ProtectedRoutesAdmin />}>
        <Route path="/crear-paciente" element={<CrearPaciente />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/home" element={<Home selectedPaciente={selectedPaciente} setSelectedPaciente={setSelectedPaciente}/>} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-paciente-user" element={<CrearPacienteUser />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-entrevista/:codigo" element={<Entrevista />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-capacidad-funcional/:codigo" element={<CapacidadFuncional />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-antropometria/:codigo" element={<Antropometria />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-tension-arterial/:codigo" element={<TensionArterial />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-valoracion-funcional/:codigo" element={<ValoracionFuncional />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path="/crear-otros/:codigo" element={<Otros />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Valoracion-Funcional/:idTipo/:paciente' element={<MainValoracionFuncional />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Tension-Arterial/:idTipo/:paciente' element={<MainTensionArterial />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Otros/:idTipo/:paciente' element={<MainOtros />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Capacidad-Funcional/:idTipo/:paciente' element={<MainCapacidadFuncional />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Antropometria/:idTipo/:paciente' element={<MainAntropometria />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Entrevista/:idEntrevista/:paciente' element={<MainEntrevista />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Usuario' element={<MainUser />}/>
      </Route>
      <Route element={<ProtectedRoutesAdmin />}>
        <Route path='/ver-Admin' element={<MainAdmin />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/ver-Paciente/:idPaciente' element={<MainPaciente />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/lista-Entrevistas' element={<TableEntrevista />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/lista-Antropometria' element={<TableAntropometria />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/lista-Capacidad-Funcional' element={<TableCapacidadFuncional />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/lista-Tension-Arterial' element={<TableTensionArterial />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/lista-Valoracion-Funcional' element={<TableValoracionFuncional />}/>
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route path='/lista-Otros' element={<TableOtros />}/>
      </Route>
    </Routes>
  );
}

export default App;
