import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, TextField, Typography, Box, Grid, AppBar, Toolbar, Button } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import axios from 'axios';
import config from '../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = `https://${config.BACKEND}:3000/otros/`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;
const DELETE_URL = `https://${config.BACKEND}:3000/otros/`;
const UPDATE_URL = `https://${config.BACKEND}:3000/otros/`;

const OtrosViewer = ({ idTipo, paciente }) => {
    console.log(`El valor del paciente es: ${paciente}`);
    console.log(`El valor del tipo es: ${idTipo}`);

    const [codigo, setCodigo] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [lawtonBrody, setLawtonBrody] = useState(0);
    const [moca, setMoca] = useState(0);
    const [codigoInforme, setCodigoInforme] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const [openGood, setOpenGood] = useState(false);
    const [originalValues, setOriginalValues] = useState({});

    useEffect(() => {
        if (paciente) {
            axios.get(PACIENTE_URL + paciente, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const pacienteData = response.data;
                    setCodigo(pacienteData.codHistorico);
                    setNombre(pacienteData.firstname);
                    setApellidos(pacienteData.lastname);
                })
                .catch(error => {
                    console.error('Error fetching patient data:', error);
                });

            axios.get(API_URL + idTipo, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const otrosData = response.data;
                    const fecha = formatearFecha(otrosData.createdAt);

                    setCodigoInforme(otrosData.codigo);
                    setCreatedAt(fecha);
                    setLawtonBrody(otrosData.lawtonBrody);
                    setMoca(otrosData.moca);
                })
                .catch(error => {
                    console.error('Error fetching otros data:', error);
                });
        }
    }, [idTipo, paciente]);

    const formatearFecha = (date) => {
        const formattedDate = new Date(date);
        const day = String(formattedDate.getDate()).padStart(2, '0');
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = formattedDate.getFullYear();
        const fecha = `${day}-${month}-${year}`;

        return fecha;
    }

    const handleEdit = () => {
        setOriginalValues({
            lawtonBrody,
            moca
        });
        setIsEditing(true);
    };

    const handleSave = () => {
        axios.put(UPDATE_URL + codigoInforme, {
            lawtonBrody: parseFloat(lawtonBrody),
            moca: parseFloat(moca)
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setOpenGood(true);
                console.log('Changes saved:', response.data);
                setIsEditing(false);
            })
            .catch(error => {
                console.error('Error saving changes:', error);
            });
    };

    const handleDelete = () => {
        if (window.confirm('¿Está seguro de que desea eliminar este informe?')) {
            axios.delete(DELETE_URL + codigoInforme, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log('Informe eliminado:', response.data);
                    window.location.href = "/home";
                    // Redirect or update the state as needed
                })
                .catch(error => {
                    console.error('Error deleting the report:', error);
                });
        }
    };

    const handleCancel = () => {
        setLawtonBrody(originalValues.lawtonBrody);
        setMoca(originalValues.moca);
        setIsEditing(false);
    };

    const handleExportPDF = () => {
        setIsEditing(false);
        const input = document.getElementById('pdf-content');
        html2canvas(input, {
            scale: 2,
            backgroundColor: null,
            useCORS: true,
            ignoreElements: (element) => element.tagName === 'BUTTON',
        })
            .then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgProperties = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`valoracion_cognitiva_funcional_${nombre}${apellidos}_${createdAt}.pdf`);
            })
            .catch(error => {
                console.error('Error exporting PDF:', error);
            });
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
            <Box sx={{ maxWidth: 1000, width: '100%', backgroundColor: 'white', padding: 2 }}>
                <Box id="pdf-content" sx={{ padding: 2 }}>
                    <AppBar position="static" sx={{ marginBottom: 4, marginTop: 8, boxShadow: 'none', border: 'none' }}>
                        <Toolbar style={{ backgroundColor: 'white' }}>
                            <img src={logoUsal} alt="Logo Izquierda" style={{ height: '70px', width: 'auto', marginRight: 'auto' }} />
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>VALORACIÓN COGNITIVA Y FUNCIONAL</Typography>
                            <img src={logoCiencias} alt="Logo Derecha" style={{ height: '70px', width: 'auto', marginLeft: 'auto' }} />
                        </Toolbar>
                    </AppBar>
                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>DATOS DEL PACIENTE</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">CÓDIGO</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={codigo}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">FECHA</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={createdAt}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">NOMBRE</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={nombre}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">APELLIDOS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={apellidos}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>DATOS DE LA PRUEBA</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">LAWTON Y BRODY (puntos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={lawtonBrody}
                                onChange={handleInputChange(setLawtonBrody)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MOCA (puntos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={moca}
                                onChange={handleInputChange(setMoca)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        {isEditing ? (
                            <>
                                <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>Guardar Cambios</Button>
                                <Button variant="contained" color="error" onClick={handleDelete} sx={{ marginRight: 2 }}>Eliminar</Button>
                                <Button variant="contained" onClick={handleCancel}>Cancelar edición</Button>
                            </>
                        ) : (
                            <>
                                <Button variant="contained" color="primary" onClick={handleEdit} sx={{ marginRight: 2 }}>Editar</Button>
                                <Button variant="contained" color="secondary" onClick={handleExportPDF} sx={{ marginRight: 2 }}>Exportar PDF</Button>
                                <Button variant="contained" color="error" onClick={handleDelete}>Eliminar</Button>
                            </>
                        )}
                    </Box>
                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>¡Informe actualizado!</Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Box>
    );
};

export default OtrosViewer;

