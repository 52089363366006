import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const ListItemWithNavigation = ({ icon: Icon, text, to }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export const mainListItems = (
  <React.Fragment>
    <ListItemWithNavigation icon={DashboardIcon} text="Home" to="/homeAdmin" />
    <ListItemWithNavigation icon={HowToRegIcon} text="Crear Usuario" to="/crear-usuario" />
    <ListItemWithNavigation icon={PersonAddAltIcon} text="Crear Paciente" to="/crear-paciente" />
  </React.Fragment>
);

