import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, TextField, Typography, Box, Grid, AppBar, Toolbar, Button } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import axios from 'axios';
import config from '../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = `https://${config.BACKEND}:3000/medidas/`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;
const DELETE_URL = `https://${config.BACKEND}:3000/medidas/`;
const UPDATE_URL = `https://${config.BACKEND}:3000/medidas/`;

const MedidasAntropometricasViewer = ({ idTipo, paciente }) => {
    console.log(`El valor del paciente es: ${paciente}`);
    console.log(`El valor del tipo es: ${idTipo}`);

    const [codigo, setCodigo] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [talla, setTalla] = useState(0.0);
    const [peso, setPeso] = useState(0.0);
    const [perimetroAbdominal1, setPerimetroAbdominal1] = useState(0.0);
    const [perimetroAbdominal2, setPerimetroAbdominal2] = useState(0.0);
    const [perimetroAbdominal3, setPerimetroAbdominal3] = useState(0.0);
    const [mediaPerimetroAbdominal, setMediaPerimetroAbdominal] = useState(0.0);
    const [grasa, setGrasa] = useState(0.0);
    const [nivelGrasa, setNivelGrasa] = useState(0);
    const [musculo, setMusculo] = useState(0.0);
    const [imc, setImc] = useState(0.0);
    const [bd, setBd] = useState(0.0);
    const [bi, setBi] = useState(0.0);
    const [pd, setPd] = useState(0.0);
    const [pi, setPi] = useState(0.0);
    const [pesoBrazosPiernas, setPesoBrazosPiernas] = useState(0.0);
    const [smi, setSmi] = useState(0.0);
    const [codigoInforme, setCodigoInforme] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const [openGood, setOpenGood] = useState(false);
    const [originalValues, setOriginalValues] = useState({});

    useEffect(() => {
        if (paciente) {
            axios.get(PACIENTE_URL + paciente, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const pacienteData = response.data;
                    setCodigo(pacienteData.codHistorico);
                    setNombre(pacienteData.firstname);
                    setApellidos(pacienteData.lastname);
                })
                .catch(error => {
                    console.error('Error fetching patient data:', error);
                });

            axios.get(API_URL + idTipo, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const medidasData = response.data;
                    const fecha = formatearFecha(medidasData.createdAt);

                    setCodigoInforme(medidasData.codigo);
                    setCreatedAt(fecha);
                    setTalla(medidasData.talla);
                    setPeso(medidasData.peso);
                    setPerimetroAbdominal1(medidasData.perimetroAbdominal1);
                    setPerimetroAbdominal2(medidasData.perimetroAbdominal2);
                    setPerimetroAbdominal3(medidasData.perimetroAbdominal3);
                    setMediaPerimetroAbdominal(medidasData.mediaPerimetroAbdominal);
                    setGrasa(medidasData.grasa);
                    setNivelGrasa(medidasData.nivelGrasa);
                    setMusculo(medidasData.musculo);
                    setImc(medidasData.imc);
                    setBd(medidasData.bd);
                    setBi(medidasData.bi);
                    setPd(medidasData.pd);
                    setPi(medidasData.pi);
                    setPesoBrazosPiernas(medidasData.pesoBrazosPiernas);
                    setSmi(medidasData.smi);
                })
                .catch(error => {
                    console.error('Error fetching antropometric data:', error);
                });
        }
    }, [idTipo, paciente]);

    const formatearFecha = (date) => {
        const formattedDate = new Date(date);
        const day = String(formattedDate.getDate()).padStart(2, '0');
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = formattedDate.getFullYear();
        const fecha = `${day}-${month}-${year}`;

        return fecha;
    }

    const handleEdit = () => {
        setOriginalValues({
            talla,
            peso,
            perimetroAbdominal1,
            perimetroAbdominal2,
            perimetroAbdominal3,
            mediaPerimetroAbdominal,
            grasa,
            nivelGrasa,
            musculo,
            imc,
            bd,
            bi,
            pd,
            pi,
            pesoBrazosPiernas,
            smi
        });
        setIsEditing(true);
    };

    const handleSave = () => {
        axios.put(UPDATE_URL + codigoInforme, {
            talla: parseFloat(talla),
            peso: parseFloat(peso),
            perimetroAbdominal1: parseFloat(perimetroAbdominal1),
            perimetroAbdominal2: parseFloat(perimetroAbdominal2),
            perimetroAbdominal3: parseFloat(perimetroAbdominal3),
            mediaPerimetroAbdominal: parseFloat(mediaPerimetroAbdominal),
            grasa: parseFloat(grasa),
            nivelGrasa: parseInt(nivelGrasa),
            musculo: parseFloat(musculo),
            imc: parseFloat(imc),
            bd: parseFloat(bd),
            bi: parseFloat(bi),
            pd: parseFloat(pd),
            pi: parseFloat(pi),
            pesoBrazosPiernas: parseFloat(pesoBrazosPiernas),
            smi: parseFloat(smi)
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setOpenGood(true);
                console.log('Changes saved:', response.data);
                setIsEditing(false);
            })
            .catch(error => {
                console.error('Error saving changes:', error);
            });
    };

    const handleDelete = () => {
        if (window.confirm('¿Está seguro de que desea eliminar este informe?')) {
            axios.delete(DELETE_URL + codigoInforme, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log('Informe eliminado:', response.data);
                    window.location.href = "/home";
                    // Redirect or update the state as needed
                })
                .catch(error => {
                    console.error('Error deleting the report:', error);
                });
        }
    };

    const handleCancel = () => {
        setTalla(originalValues.talla);
        setPeso(originalValues.peso);
        setPerimetroAbdominal1(originalValues.perimetroAbdominal1);
        setPerimetroAbdominal2(originalValues.perimetroAbdominal2);
        setPerimetroAbdominal3(originalValues.perimetroAbdominal3);
        setMediaPerimetroAbdominal(originalValues.mediaPerimetroAbdominal);
        setGrasa(originalValues.grasa);
        setNivelGrasa(originalValues.nivelGrasa);
        setMusculo(originalValues.musculo);
        setImc(originalValues.imc);
        setBd(originalValues.bd);
        setBi(originalValues.bi);
        setPd(originalValues.pd);
        setPi(originalValues.pi);
        setPesoBrazosPiernas(originalValues.pesoBrazosPiernas);
        setSmi(originalValues.smi);
        setIsEditing(false);
    };

    const handleExportPDF = () => {
        setIsEditing(false);
        const input = document.getElementById('pdf-content');
        html2canvas(input, {
            scale: 2,
            backgroundColor: null,
            useCORS: true,
            ignoreElements: (element) => element.tagName === 'BUTTON',
        })
            .then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgProperties = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`medidas_antropometricas_${nombre}${apellidos}_${createdAt}.pdf`);
            })
            .catch(error => {
                console.error('Error exporting PDF:', error);
            });
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
            <Box sx={{ maxWidth: 1000, width: '100%', backgroundColor: 'white', padding: 2 }}>
                <Box id="pdf-content" sx={{ padding: 2 }}>
                    <AppBar position="static" sx={{ marginBottom: 4, marginTop: 8, boxShadow: 'none', border: 'none' }}>
                        <Toolbar style={{ backgroundColor: 'white' }}>
                            <img src={logoUsal} alt="Logo Izquierda" style={{ height: '70px', width: 'auto', marginRight: 'auto' }} />
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>ANTROPOMETRÍA</Typography>
                            <img src={logoCiencias} alt="Logo Derecha" style={{ height: '70px', width: 'auto', marginLeft: 'auto' }} />
                        </Toolbar>
                    </AppBar>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>DATOS DEL PACIENTE</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">CÓDIGO</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={codigo}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">FECHA</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={createdAt}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">NOMBRE</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={nombre}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">APELLIDOS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={apellidos}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>MEDIDAS ANTROPOMÉTRICAS</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TALLA (m)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={talla}
                                onChange={handleInputChange(setTalla)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PESO (kg)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={peso}
                                onChange={handleInputChange(setPeso)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Perímetro Abdominal</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 1</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={perimetroAbdominal1}
                                onChange={handleInputChange(setPerimetroAbdominal1)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 2</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={perimetroAbdominal2}
                                onChange={handleInputChange(setPerimetroAbdominal2)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 3</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={perimetroAbdominal3}
                                onChange={handleInputChange(setPerimetroAbdominal3)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIA TOTAL</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={mediaPerimetroAbdominal}
                                onChange={handleInputChange(setMediaPerimetroAbdominal)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Impedancia</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">% GRASA</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={grasa}
                                onChange={handleInputChange(setGrasa)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">GRASA VISCERAL</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={nivelGrasa}
                                onChange={handleInputChange(setNivelGrasa)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">% MÚSCULO</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={musculo}
                                onChange={handleInputChange(setMusculo)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">IMC (kg/m^2)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={imc}
                                onChange={handleInputChange(setImc)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Medidas brazos y piernas</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">BRAZO DERECHO (kg)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={bd}
                                onChange={handleInputChange(setBd)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">BRAZO IZQUIERDO (kg)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={bi}
                                onChange={handleInputChange(setBi)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PIERNA DERECHA (kg)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={pd}
                                onChange={handleInputChange(setPd)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PIERNA IZQUIERDA (kg)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={pi}
                                onChange={handleInputChange(setPi)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PESO DE BRAZOS Y PIERNAS (kg)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={pesoBrazosPiernas}
                                onChange={handleInputChange(setPesoBrazosPiernas)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MASA MUSCULAR APENDICULAR (kg/m^2)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={smi}
                                onChange={handleInputChange(setSmi)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    {isEditing ? (
                        <>
                            <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>Guardar Cambios</Button>
                            <Button variant="contained" color="error" onClick={handleDelete} sx={{ marginRight: 2 }}>Eliminar</Button>
                            <Button variant="contained" onClick={handleCancel}>Cancelar edición</Button>
                        </>
                    ) : (
                        <>
                            <Button variant="contained" color="primary" onClick={handleEdit} sx={{ marginRight: 2 }}>Editar</Button>
                            <Button variant="contained" color="secondary" onClick={handleExportPDF} sx={{ marginRight: 2 }}>Exportar PDF</Button>
                            <Button variant="contained" color="error" onClick={handleDelete}>Eliminar</Button>
                        </>
                    )}
                </Box>
                <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                    <Alert severity="success" onClose={() => setOpenGood(false)}>¡Informe actualizado!</Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default MedidasAntropometricasViewer;