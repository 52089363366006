import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ProtectedRoutes = () => {
  const token = cookies.get("TOKEN");
  const admin = localStorage.getItem("admin");

  // Verificar que hay un token válido y que admin es igual a "false"
  return token && admin === "false" ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
