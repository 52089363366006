import React from 'react';
import Box from '@mui/material/Box';
import logo from '../../images/marca-FAC-ENFERMERIA-FISIOTERAPIA-color.png'; 

const Logo = () => {
  return (
    <Box
      component="img"
      src={logo}
      alt="Logo"
      sx={{
        position: 'fixed',   // Fixed position to keep it at the bottom right
        bottom: 10,
        right: 10,
        width: '250px',      // Adjust the size as needed
        padding: '10px',     // Adjust padding as necessary
        zIndex: 1000,        // Ensure it stays on top of other elements
      }}
    />
  );
};

export default Logo;
