import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import config from '../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/pacientes/actives";

const columns = [
    { id: 'codHistorico', label: 'ID', minWidth: 170 },
    { id: 'nombre', label: 'Nombre y Apellidos', minWidth: 170 },
    {
        id: 'dateBirth',
        label: 'Fecha de Nascimiento',
        minWidth: 170,
        align: 'right',
        format: (value) => {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
    },
    { id: 'grupo', label: 'Grupo', minWidth: 170, align: 'right' },
    { id: 'sexo', label: 'Sexo', minWidth: 170, align: 'right' },
    { id: 'actions', label: '', minWidth: 50, align: 'right' }
];

function PatientsTable({ setSelectedPaciente }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [filterGroup, setFilterGroup] = useState('');
    const [filterSexo, setFilterSexo] = useState('');

    useEffect(() => {
        fetch(API_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(paciente => ({
                    id: paciente.id,
                    codHistorico: paciente.codHistorico,
                    nombre: `${paciente.firstname} ${paciente.lastname}`,
                    dateBirth: paciente.dateBirth,
                    grupo: getGrupoName(paciente.grupo),
                    sexo: paciente.sexo === 1 ? 'Hombre' : 'Mujer',
                    actions: null
                }));
                setRows(formattedData);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (row) => {
        console.log('Código del paciente:', row.id);
        window.location.href = `/ver-Paciente/${row.id}`;
        //setSelectedPaciente(codHistorico);
        //console.log('Código del paciente guardado en selectedItem:', codHistorico);
    };

    const handleIconClick = (event, id) => {
        event.stopPropagation();
        window.location.href = `/ver-Paciente/${id}`;
    };

    const getGrupoName = (grupoNumber) => {
        switch (grupoNumber) {
            case 1:
                return 'Pizarrales';
            case 2:
                return 'Amis';
            case 3:
                return 'Tierra charra';
            case 4:
                return 'María Auxiliadora';
            case 5:
                return 'Juan de la Fuente';
            case 6:
                return 'Buenos aires';
            case 7:
                return 'Tejares';
            case 8:
                return 'Chamberí';
            case 9:
                return 'San Isidro';
            case 10:
                return 'Puente Ladrillo';
            case 11:
                return 'La Vega';
            case 12:
                return 'San José';
            case 13:
                return 'Otro';
            default:
                return '';
        }
    };

    const resetFilters = () => {
        setSearchName('');
        setFilterGroup('');
        setFilterSexo('');
    };

    const filteredRows = rows.filter(row => {
        return (
            (searchName === '' || row.nombre.toLowerCase().includes(searchName.toLowerCase())) &&
            (filterGroup === '' || row.grupo === getGrupoName(filterGroup)) &&
            (filterSexo === '' || row.sexo === filterSexo)
        );
    });

    return (
        <>
            <Typography variant="h6" component="h2" gutterBottom color='#0D6AEE'>
                Lista de Pacientes
            </Typography>
            <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
                <TextField
                    label="Buscar por nombre"
                    variant="outlined"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    style={{ marginRight: '20px' }}
                />
                <Select
                    value={filterGroup}
                    onChange={(e) => setFilterGroup(e.target.value)}
                    displayEmpty
                    style={{ minWidth: 200, marginRight: '20px' }}
                >
                    <MenuItem value="">Todos los grupos</MenuItem>
                    <MenuItem value={1}>Pizarrales</MenuItem>
                    <MenuItem value={2}>Amis</MenuItem>
                    <MenuItem value={3}>Tierra charra</MenuItem>
                    <MenuItem value={4}>María Auxiliadora</MenuItem>
                    <MenuItem value={5}>Juan de la Fuente</MenuItem>
                    <MenuItem value={6}>Buenos aires</MenuItem>
                    <MenuItem value={7}>Tejares</MenuItem>
                    <MenuItem value={8}>Chamberí</MenuItem>
                    <MenuItem value={9}>San Isidro</MenuItem>
                    <MenuItem value={10}>Puente Ladrillo</MenuItem>
                    <MenuItem value={11}>La Vega</MenuItem>
                    <MenuItem value={12}>San José</MenuItem>
                    <MenuItem value={13}>Otro</MenuItem>
                </Select>
                <Select
                    value={filterSexo}
                    onChange={(e) => setFilterSexo(e.target.value)}
                    displayEmpty
                    style={{ minWidth: 200, marginRight: '20px' }}
                >
                    <MenuItem value="">Todos los sexos</MenuItem>
                    <MenuItem value="Hombre">Hombre</MenuItem>
                    <MenuItem value="Mujer">Mujer</MenuItem>
                </Select>
                <Button variant="contained" onClick={resetFilters} style={{ height: '56px' }}>
                    Resetear Filtros
                </Button>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 650 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textDecoration: 'underline', color: '#0D6AEE' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No hay pacientes registrados
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            onClick={() => handleRowClick(row)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {columns.map((column) => {
                                                if (column.id === 'actions') {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <IconButton onClick={(event) => handleIconClick(event, row.id)}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    );
                                                }
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Resultados por página:"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default PatientsTable;
