import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Axios from 'axios';
import config from '../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/pacientes";
const DELETE_URL = "https://" + config.BACKEND + ":3000/pacientes/delete";
const ACTIVATE_URL = "https://" + config.BACKEND + ":3000/pacientes/activate";

const columns = [
    { id: 'codHistorico', label: 'código', minWidth: 170 },
    { id: 'nombre', label: 'nombre', minWidth: 170 },
    { id: 'telefono', label: 'teléfono', minWidth: 170 },
    { id: 'dateBirth', label: 'fecha de nacimiento (dd-mm-yyyy)', minWidth: 170, align: 'right', format: (value) => new Date(value).toLocaleDateString() },
];

function PatientsTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [deletedRowIds, setDeletedRowIds] = useState([]);
    const [filterCodHistorico, setFilterCodHistorico] = useState('');
    const [filterNombre, setFilterNombre] = useState('');
    const [sortDateBirth, setSortDateBirth] = useState(null); // null, 'asc', 'desc'
    const tableRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tableRef.current && !tableRef.current.contains(event.target) && buttonRef.current !== event.target) {
                setSelectedRow(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tableRef, buttonRef]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getPacientes = async () => {
        try {
            const response = await Axios.get(API_URL, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const pacientes = response.data;
            const inactivePacienteIds = pacientes.filter(paciente => !paciente.active).map(paciente => paciente.codHistorico);
            setDeletedRowIds(inactivePacienteIds);
            
            const mappedPacientes = pacientes.map(paciente => ({
                codHistorico: paciente.codHistorico,
                nombre: `${paciente.firstname} ${paciente.lastname}`,
                telefono: paciente.telefono,
                dateBirth: paciente.dateBirth,
                active: paciente.active
            }));
            setRows(mappedPacientes);
        } catch (error) {
            console.error('Error al obtener los pacientes:', error);
        }
    };

    useEffect(() => {
        getPacientes();
    }, []);

    const handleRowClick = (row) => {
        setSelectedRow(row);
    };

    const handleToggleActive = async () => {
        try {
            if(selectedRow.active === true){
                await Axios.put(`${DELETE_URL}/${selectedRow.codHistorico}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            } else {
                await Axios.put(`${ACTIVATE_URL}/${selectedRow.codHistorico}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            }

            setDeletedRowIds(prevDeletedRowIds => {
                if (selectedRow.active) {
                    return prevDeletedRowIds.filter(id => id !== selectedRow.codHistorico);
                } else {
                    return [...prevDeletedRowIds, selectedRow.codHistorico];
                }
            });
            setSelectedRow(null);
            getPacientes();
        } catch (error) {
            console.error('Error al activar o eliminar al paciente:', error);
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'codHistorico':
                setFilterCodHistorico(value);
                break;
            case 'nombre':
                setFilterNombre(value);
                break;
            default:
                break;
        }
    };

    const handleSortDateBirth = () => {
        if (sortDateBirth === 'asc') {
            setSortDateBirth('desc');
        } else {
            setSortDateBirth('asc');
        }
    };

    const handleResetFilters = () => {
        setFilterCodHistorico('');
        setFilterNombre('');
        setSortDateBirth(null);
    };

    const filteredRows = rows
        .filter(row => filterCodHistorico === '' || row.codHistorico.toString().includes(filterCodHistorico))
        .filter(row => filterNombre === '' || row.nombre.toLowerCase().includes(filterNombre.toLowerCase()))
        .sort((a, b) => {
            if (sortDateBirth === 'asc') {
                return new Date(a.dateBirth) - new Date(b.dateBirth);
            } else if (sortDateBirth === 'desc') {
                return new Date(b.dateBirth) - new Date(a.dateBirth);
            }
            return 0;
        });

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="h2" gutterBottom color='#0D6AEE'>
                    Lista de Pacientes
                </Typography>
                <Button 
                    ref={buttonRef} 
                    variant="contained" 
                    sx={{
                        backgroundColor: selectedRow ? '#0D6AEE' : '#0D6AEE80',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: selectedRow ? '#0D6AEE' : '#0D6AEE80'
                        },
                        cursor: selectedRow ? 'pointer' : 'not-allowed'
                    }}
                    disabled={!selectedRow} 
                    onClick={handleToggleActive}
                >
                    {selectedRow && selectedRow.active ? 'Dar de Baja' : 'Activar'}
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <TextField
                    name="codHistorico"
                    label="Filtrar por Código"
                    value={filterCodHistorico}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <TextField
                    name="nombre"
                    label="Filtrar por Nombre"
                    value={filterNombre}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <Button variant="contained" onClick={handleSortDateBirth} style={{ marginRight: 16 }}>
                    Ordenar por fecha de nacimiento {sortDateBirth === 'asc' ? '⬆️' : '⬇️'}
                </Button>
                <Button variant="contained" onClick={handleResetFilters}>
                    Resetear Filtros
                </Button>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} ref={tableRef}>
                <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textDecoration: 'underline', color: '#0D6AEE' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No hay pacientes registrados
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow 
                                            hover 
                                            role="checkbox" 
                                            tabIndex={-1} 
                                            key={row.codHistorico} 
                                            onClick={() => handleRowClick(row)}
                                            style={{ 
                                                cursor: 'pointer', 
                                                backgroundColor: deletedRowIds.includes(row.codHistorico) ? 'lightgrey' : 'white'
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'string'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default PatientsTable;
