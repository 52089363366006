import React from 'react';
import { TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Alert, Snackbar } from '@mui/material';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/tension/";
const PACIENTE_URL = "https://" + config.BACKEND + ":3000/pacientes/";

const TensionArterial = ({codigo}) => {
    const [codHistorico] = React.useState(codigo);
    const [pmm, setPmm] = React.useState('');
    const [pas, setPas] = React.useState('');
    const [pad, setPad] = React.useState('');

    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const guardarDatos = () => {
        let paciente;
        let userId;
        let pacienteId;

        axios.get(PACIENTE_URL + codHistorico, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                paciente = response.data;
                console.log(`Este es el objeto: ${paciente.id}`);

                userId = localStorage.getItem("id");
                pacienteId = paciente.id.toString();

                axios.post(API_URL + pacienteId + "/" + userId, {
                    pmm: parseInt(pmm),
                    pas: parseInt(pas),
                    pad: parseInt(pad)
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    setOpenGood(true);
                    window.location.href = "/home";
                }).catch(error => {
                    console.error('Error al guardar los datos de tensión arterial:', error);
                });
            })
            .catch(error => {
                setOpenError(true);
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img
                            src={logoUsal}
                            alt="Logo Izquierda"
                            style={{ height: '100px', width: 'auto', marginRight: 'auto' }}
                        />
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}
                        >
                            TENSIÓN ARTERIAL
                        </Typography>
                        <img
                            src={logoCiencias}
                            alt="Logo Derecha"
                            style={{ height: '100px', width: 'auto', marginLeft: 'auto' }}
                        />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <TextField
                        label="Código del Paciente"
                        required
                        variant="outlined"
                        sx={{ width: '25%', marginBottom: 4 }}
                        value={codHistorico}
                    />
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={4}>
                            <TextField fullWidth label="PMM (pulsaciones por minuto)" variant="outlined" value={pmm} onChange={(e) => setPmm(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="PAS(mmHg)" variant="outlined" value={pas} onChange={(e) => setPas(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="PAD(mmHg)" variant="outlined" value={pad} onChange={(e) => setPad(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>¡Datos guardados correctamente!</Alert>
                    </Snackbar>

                    <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                        <Alert severity="error" onClose={() => setOpenError(false)}>No hay pacientes con ese código.</Alert>
                    </Snackbar>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Button variant="contained" color="primary" onClick={guardarDatos}>
                            Guardar Datos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default TensionArterial;

