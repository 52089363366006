import React from 'react';
import { TextField, Typography, Box, Grid, AppBar, Toolbar, Button, MenuItem, Select, FormControl, InputLabel, Snackbar, Alert } from '@mui/material';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Logo from '../../../admin/Logo';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = `https://${config.BACKEND}:3000/pacientes`;

const CrearPaciente = () => {
    const [codHistorico, setCodHistorico] = React.useState('');
    const [firstname, setFirstname] = React.useState('');
    const [lastname, setLastname] = React.useState('');
    const [telefono, setTelefono] = React.useState('');
    const [dateBirth, setDateBirth] = React.useState('');
    const [grupo, setGrupo] = React.useState('');
    const [sexo, setSexo] = React.useState('');
    const [viven, setViven] = React.useState('');
    const [nivelEducacional, setNivelEducacional] = React.useState('');
    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const handleSave = () => {
        let numGrupo = comprobarGrupo(grupo);
        let numSexo = comprobarSexo(sexo);
        let numViven = comprobarViven(viven);
        let numNivelEducacional = comprobarNivelEducacional(nivelEducacional);
        let fecha = convertirFecha(dateBirth);

        axios.post(API_URL, {
            codHistorico: codHistorico,
            firstname: firstname,
            lastname: lastname,
            telefono: telefono,
            dateBirth: fecha,
            grupo: numGrupo,
            sexo: numSexo,
            viven: numViven,
            nivelEducacional: numNivelEducacional
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            setOpenGood(true);
            window.location.href = "/home";
        })
        .catch(error => {
            console.error('Error al guardar el paciente:', error);
            setOpenError(true);
        });
    };

    function comprobarGrupo(grupo){
        let numGrupo = 0;

        if (grupo === "Pizarrales") {
            numGrupo = 1;
        } else if (grupo === "Amis") {
            numGrupo = 2;
        } else if (grupo === "Tierra charra") {
            numGrupo = 3;
        } else if (grupo === "María Auxiliadora") {
            numGrupo = 4;
        } else if (grupo === "Juan de la Fuente") {
            numGrupo = 5;
        } else if (grupo === "Buenos aires") {
            numGrupo = 6;
        } else if (grupo === "Tejares") {
            numGrupo = 7;
        } else if (grupo === "Chamberí") {
            numGrupo = 8;
        } else if (grupo === "San Isidro") {
            numGrupo = 9;
        } else if (grupo === "Puente Ladrillo") {
            numGrupo = 10;
        } else if (grupo === "La Vega") {
            numGrupo = 11;
        } else if (grupo === "San José") {
            numGrupo = 12;
        } else{
            numGrupo = 13;
        }

        return numGrupo;
    }

    function comprobarSexo(sexo){
        let numSexo = 0;

        if (sexo === "Hombre") {
            numSexo = 1;
        } else {
            numSexo = 2;
        }

        return numSexo;
    }

    function comprobarViven(viven){
        let numViven = 0;

        if (viven === "solo") {
            numViven = 0;
        } else if (viven === "pareja") {
            numViven = 1;
        } else {
            numViven = 2;
        }

        return numViven;
    }

    function comprobarNivelEducacional(nivelEducacional){
        let numNivelEducacional = 0;

        if (nivelEducacional === "Analfabeto") {
            numNivelEducacional = 0;
        } else if (nivelEducacional === "Estudios primarios incompletos") {
            numNivelEducacional = 1;
        } else if (nivelEducacional === "Estudios primarios") {
            numNivelEducacional = 2;
        } else if (nivelEducacional === "Estudios secundarios") {
            numNivelEducacional = 3;
        } else {
            numNivelEducacional = 4;
        }

        return numNivelEducacional;
    }

    function convertirFecha(fechaStr) {
        // Separa la cadena en partes (día, mes y año)
        const [dia, mes, anio] = fechaStr.split('-').map(Number);
        
        // Los meses en JavaScript están indexados desde 0 (enero) hasta 11 (diciembre)
        const mesIndexado = mes - 1;
        
        // Crea un nuevo objeto Date (año, mes, día)
        const fecha = new Date(anio, mesIndexado, dia);
        
        return fecha;
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img
                            src={logoUsal}
                            alt="Logo Izquierda"
                            style={{ height: '100px', width: 'auto', marginRight: 'auto' }}
                        />
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}
                        >
                            CREAR PACIENTE
                        </Typography>
                        <Logo />
                        <img
                            src={logoCiencias}
                            alt="Logo Derecha"
                            style={{ height: '100px', width: 'auto', marginLeft: 'auto' }}
                        />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <TextField
                        label="Código del Paciente"
                        required
                        variant="outlined"
                        fullWidth
                        value={codHistorico}
                        onChange={(e) => setCodHistorico(e.target.value)}
                        sx={{ width: '25%', marginBottom: 4 }}
                    />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Nombre" variant="outlined" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Apellidos" variant="outlined" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: 4 }}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Teléfono" variant="outlined" value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Fecha de Nacimiento" type="date" variant="outlined" value={dateBirth} onChange={(e) => setDateBirth(e.target.value)} InputLabelProps={{ shrink: true }} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: 4 }}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Grupo</InputLabel>
                                <Select value={grupo} onChange={(e) => setGrupo(e.target.value)} label="Grupo">
                                    {["Pizarrales", "Amis", "Tierra charra", "María Auxiliadora", "Juan de la Fuente", "Buenos aires", "Tejares", "Chamberí", "San Isidro", "Puente Ladrillo", "La Vega", "San José", "Vidal"].map((item, index) => (
                                        <MenuItem key={index} value={index}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Sexo</InputLabel>
                                <Select value={sexo} onChange={(e) => setSexo(e.target.value)} label="Sexo">
                                    <MenuItem value={1}>Hombre</MenuItem>
                                    <MenuItem value={2}>Mujer</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Viven</InputLabel>
                                <Select value={viven} onChange={(e) => setViven(e.target.value)} label="Viven">
                                    <MenuItem value={0}>Solo</MenuItem>
                                    <MenuItem value={1}>Pareja</MenuItem>
                                    <MenuItem value={2}>Familiares</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Nivel Educacional</InputLabel>
                                <Select value={nivelEducacional} onChange={(e) => setNivelEducacional(e.target.value)} label="Nivel Educacional">
                                    <MenuItem value={0}>Analfabeto</MenuItem>
                                    <MenuItem value={1}>Estudios primarios incompletos</MenuItem>
                                    <MenuItem value={2}>Estudios primarios</MenuItem>
                                    <MenuItem value={3}>Estudios secundarios</MenuItem>
                                    <MenuItem value={4}>Estudios universitarios</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>¡Paciente creado correctamente!</Alert>
                    </Snackbar>
                    <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                        <Alert severity="error" onClose={() => setOpenError(false)}>Error al crear el paciente.</Alert>
                    </Snackbar>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Guardar Datos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CrearPaciente;
