import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, InputLabel, Select, Snackbar, Alert, TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Checkbox, FormControlLabel } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import axios from 'axios';
import config from '../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = `https://${config.BACKEND}:3000/entrevista/`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;
const UPDATE_PACIENTE = `https://${config.BACKEND}:3000/pacientes/`;
const DELETE_URL = `https://${config.BACKEND}:3000/entrevistas/`;
const UPDATE_URL = `https://${config.BACKEND}:3000/entrevistas/`;

const EntrevistaViewer = ({ idEntrevista, paciente }) => {
    const [codigo, setCodigo] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [telefono, setTelefono] = useState('');
    const [dateBirth, setDateBirth] = useState('');
    const [numViven, setNumViven] = useState('');
    const [numGrupo, setNumGrupo] = useState('');
    const [numNivelEducacional, setNumNivelEducacional] = useState('');
    const [numSexo, setNumSexo] = useState('');

    // Fields for Entrevista
    const [codigoEntrevista, setCodigoEntrevista] = useState('');
    const [edad, setEdad] = useState(0);
    const [numFumador, setNumFumador] = useState(0);
    const [tiempoFumador, setTiempoFumador] = useState('');
    const [aniosDeshabilitacion, setAniosDeshabilitacion] = useState('');
    const [numAlcohol, setNumAlcohol] = useState(0);
    const [tiempoAlcohol, setTiempoAlcohol] = useState('');
    const [aniosDeshabilitacionAlcohol, setAniosDeshabilitacionAlcohol] = useState('');
    const [caidas12Meses, setCaidas12Meses] = useState(false);
    const [numCaidas, setNumCaidas] = useState('');
    const [ayudaLocomocion, setAyudaLocomocion] = useState(false);
    const [protesisArticulares, setProtesisArticulares] = useState(false);
    const [protesisRodilla, setProtesisRodilla] = useState(false);
    const [protesisCadera, setProtesisCadera] = useState(false);
    const [protesisHombro, setProtesisHombro] = useState(false);
    const [protesisManoDedos, setProtesisManoDedos] = useState(false);
    const [protesisOtros, setProtesisOtros] = useState('');
    const [vertigos, setVertigos] = useState(false);
    const [mareosAndar, setMareosAndar] = useState(false);
    const [cardiovascular, setCardiovascular] = useState(false);
    const [hipertension, setHipertension] = useState(false);
    const [insuficienciaCardiaca, setInsuficienciaCardiaca] = useState(false);
    const [accCerebrovascular, setAccCerebrovascular] = useState(false);
    const [anginaPecho, setAnginaPecho] = useState(false);
    const [infarto, setInfarto] = useState(false);
    const [trombosis, setTrombosis] = useState(false);
    const [otrosCardiovascular, setOtrosCardiovascular] = useState('');
    const [respiratorio, setRespiratorio] = useState(false);
    const [asma, setAsma] = useState(false);
    const [epoc, setEpoc] = useState(false);
    const [otrosRespiratorio, setOtrosRespiratorio] = useState('');
    const [genitoUrinario, setGenitoUrinario] = useState(false);
    const [infeccionUrinaria, setInfeccionUrinaria] = useState(false);
    const [calculosRenales, setCalculosRenales] = useState(false);
    const [insuficienciaRenal, setInsuficienciaRenal] = useState(false);
    const [incontinenciaUrinaria, setIncontinenciaUrinaria] = useState(false);
    const [prostata, setProstata] = useState(false);
    const [otrosGenitoUrinario, setOtrosGenitoUrinario] = useState('');
    const [endocrino, setEndocrino] = useState(false);
    const [diabetes, setDiabetes] = useState(false);
    const [hipotiroidismo, setHipotiroidismo] = useState(false);
    const [hipercolesterolemia, setHipercolesterolemia] = useState(false);
    const [osteoporosis, setOsteoporosis] = useState(false);
    const [otrosEndocrino, setOtrosEndocrino] = useState('');
    const [digestivo, setDigestivo] = useState(false);
    const [herniaHiato, setHerniaHiato] = useState(false);
    const [reflujo, setReflujo] = useState(false);
    const [pancreatitis, setPancreatitis] = useState(false);
    const [enfermedadHepatica, setEnfermedadHepatica] = useState(false);
    const [diverticulitis, setDiverticulitis] = useState(false);
    const [otrosDigestivo, setOtrosDigestivo] = useState('');
    const [musculoEsqueletico, setMusculoEsqueletico] = useState(false);
    const [pieTobillo, setPieTobillo] = useState(false);
    const [rodilla, setRodilla] = useState(false);
    const [cadera, setCadera] = useState(false);
    const [lumbar, setLumbar] = useState(false);
    const [dorsal, setDorsal] = useState(false);
    const [cervical, setCervical] = useState(false);
    const [cabeza, setCabeza] = useState(false);
    const [hombro, setHombro] = useState(false);
    const [codo, setCodo] = useState(false);
    const [manoMunieca, setManoMunieca] = useState(false);
    const [nervioso, setNervioso] = useState(false);
    const [depresion, setDepresion] = useState(false);
    const [ansiedad, setAnsiedad] = useState(false);
    const [esclerosisMultiple, setEsclerosisMultiple] = useState(false);
    const [parkinson, setParkinson] = useState(false);
    const [alzheimer, setAlzheimer] = useState(false);
    const [deterioroCognitivo, setDeterioroCognitivo] = useState(false);
    const [neuropatiaPeriferica, setNeuropatiaPeriferica] = useState(false);
    const [otrosNervioso, setOtrosNervioso] = useState('');
    const [sentidos, setSentidos] = useState(false);
    const [hipoacusia, setHipoacusia] = useState(false);
    const [tinitus, setTinitus] = useState(false);
    const [perdidaVision, setPerdidaVision] = useState(false);
    const [datosInteres, setDatosInteres] = useState('');
    const [medicacion, setMedicacion] = useState(false);
    const [antihipertensivos, setAntihipertensivos] = useState(false);
    const [antidiabeticos, setAntidiabeticos] = useState(false);
    const [estatinas, setEstatinas] = useState(false);
    const [anticoagulantes, setAnticoagulantes] = useState(false);
    const [tiroides, setTiroides] = useState(false);
    const [diuretico, setDiuretico] = useState(false);
    const [analgesicos, setAnalgesicos] = useState(false);
    const [antidepresivos, setAntidepresivos] = useState(false);
    const [ansioliticos, setAnsioliticos] = useState(false);
    const [antiosteoporoticos, setAntiosteoporoticos] = useState(false);
    const [inhalador, setInhalador] = useState(false);
    const [protectorEstomago, setProtectorEstomago] = useState(false);
    const [otrosMedicacion, setOtrosMedicacion] = useState('');
    const [venredi, setVenredi] = useState(false);
    const [terapiaOcupacional, setTerapiaOcupacional] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [openGood, setOpenGood] = useState(false);
    const [openCrear, setOpenCrear] = useState(false);
    const [originalValues, setOriginalValues] = useState({});

    useEffect(() => {
        if (paciente) {
            axios.get(PACIENTE_URL + paciente, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const pacienteData = response.data;
                    const dateBirth = pacienteData.dateBirth;
                    const fecha = formatearFecha(dateBirth);

                    setCodigo(pacienteData.codHistorico);
                    setNombre(pacienteData.firstname);
                    setApellidos(pacienteData.lastname);
                    setTelefono(pacienteData.telefono);
                    setDateBirth(fecha);
                    setNumViven(pacienteData.viven);
                    setNumGrupo(pacienteData.grupo);
                    setNumNivelEducacional(pacienteData.nivelEducacional);
                    setNumSexo(pacienteData.sexo);
                })
                .catch(error => {
                    console.error('Error fetching patient data:', error);
                });

            axios.get(API_URL + idEntrevista, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const entrevistaData = response.data;
                    console.log(`${response.data}`)
                    const fecha = formatearFecha(entrevistaData.createdAt);

                    setCodigoEntrevista(entrevistaData.codigo);
                    setCreatedAt(fecha);

                    setEdad(entrevistaData.edad);
                    setNumFumador(entrevistaData.fumador);
                    setTiempoFumador(entrevistaData.tiempoFumador);
                    setAniosDeshabilitacion(entrevistaData.aniosDeshabilitacion);
                    setNumAlcohol(entrevistaData.alcohol);
                    setTiempoAlcohol(entrevistaData.tiempoAlcohol);
                    setAniosDeshabilitacionAlcohol(entrevistaData.aniosDeshabilitacionAlcohol);
                    setCaidas12Meses(entrevistaData.caidas12Meses);
                    setNumCaidas(entrevistaData.numCaidas);
                    setAyudaLocomocion(entrevistaData.ayudaLocomocion);
                    setProtesisArticulares(entrevistaData.protesisArticulares);
                    setProtesisRodilla(entrevistaData.protesisRodilla);
                    setProtesisCadera(entrevistaData.protesisCadera);
                    setProtesisHombro(entrevistaData.protesisHombro);
                    setProtesisManoDedos(entrevistaData.protesisManoDedos);
                    setProtesisOtros(entrevistaData.protesisOtros);
                    setVertigos(entrevistaData.vertigos);
                    setMareosAndar(entrevistaData.mareosAndar);
                    setCardiovascular(entrevistaData.cardiovascular);
                    setHipertension(entrevistaData.hipertension);
                    setInsuficienciaCardiaca(entrevistaData.insuficienciaCardiaca);
                    setAccCerebrovascular(entrevistaData.accCerebrovascular);
                    setAnginaPecho(entrevistaData.anginaPecho);
                    setInfarto(entrevistaData.infarto);
                    setTrombosis(entrevistaData.trombosis);
                    setOtrosCardiovascular(entrevistaData.otrosCardiovascular);
                    setRespiratorio(entrevistaData.respiratorio);
                    setAsma(entrevistaData.asma);
                    setEpoc(entrevistaData.epoc);
                    setOtrosRespiratorio(entrevistaData.otrosRespiratorio);
                    setGenitoUrinario(entrevistaData.genitoUrinario);
                    setInfeccionUrinaria(entrevistaData.infeccionUrinaria);
                    setCalculosRenales(entrevistaData.calculosRenales);
                    setInsuficienciaRenal(entrevistaData.insuficienciaRenal);
                    setIncontinenciaUrinaria(entrevistaData.incotinenciaUrinaria);
                    setProstata(entrevistaData.prostata);
                    setOtrosGenitoUrinario(entrevistaData.otrosGenitoUrinario);
                    setEndocrino(entrevistaData.endocrino);
                    setDiabetes(entrevistaData.diabetes);
                    setHipotiroidismo(entrevistaData.hipotiroidismo);
                    setHipercolesterolemia(entrevistaData.hipercolesterolemia);
                    setOsteoporosis(entrevistaData.osteoporosis);
                    setOtrosEndocrino(entrevistaData.otrosEndocrino);
                    setDigestivo(entrevistaData.digestivo);
                    setHerniaHiato(entrevistaData.herniaHiato);
                    setReflujo(entrevistaData.reflujo);
                    setPancreatitis(entrevistaData.pancreatitis);
                    setEnfermedadHepatica(entrevistaData.enfermedadHepatica);
                    setDiverticulitis(entrevistaData.diverticulitis);
                    setOtrosDigestivo(entrevistaData.otrosDigestivo);
                    setMusculoEsqueletico(entrevistaData.musculoEsqueletico);
                    setPieTobillo(entrevistaData.pieTobillo);
                    setRodilla(entrevistaData.rodilla);
                    setCadera(entrevistaData.cadera);
                    setLumbar(entrevistaData.lumbar);
                    setDorsal(entrevistaData.dorsal);
                    setCervical(entrevistaData.cervical);
                    setCabeza(entrevistaData.cabeza);
                    setHombro(entrevistaData.hombro);
                    setCodo(entrevistaData.codo);
                    setManoMunieca(entrevistaData.mano_munieca);
                    setNervioso(entrevistaData.nervioso);
                    setDepresion(entrevistaData.depresion);
                    setAnsiedad(entrevistaData.ansiedad);
                    setEsclerosisMultiple(entrevistaData.esclerosisMultiple);
                    setParkinson(entrevistaData.parkinson);
                    setAlzheimer(entrevistaData.alzheimer);
                    setDeterioroCognitivo(entrevistaData.deterioroCognitivo);
                    setNeuropatiaPeriferica(entrevistaData.neuropatiaPeriferica);
                    setOtrosNervioso(entrevistaData.otrosNervioso);
                    setSentidos(entrevistaData.sentidos);
                    setHipoacusia(entrevistaData.hipoacusia);
                    setTinitus(entrevistaData.tinitus);
                    setPerdidaVision(entrevistaData.perdidaVision);
                    setDatosInteres(entrevistaData.datosInteres);
                    setMedicacion(entrevistaData.medicacion);
                    setAntihipertensivos(entrevistaData.antihipertensivos);
                    setAntidiabeticos(entrevistaData.antidiabeticos);
                    setEstatinas(entrevistaData.estatinas);
                    setAnticoagulantes(entrevistaData.anticoagulantes);
                    setTiroides(entrevistaData.tiroides);
                    setDiuretico(entrevistaData.diuretico);
                    setAnalgesicos(entrevistaData.analgesicos);
                    setAntidepresivos(entrevistaData.antidepresivos);
                    setAnsioliticos(entrevistaData.ansioliticos);
                    setAntiosteoporoticos(entrevistaData.antiosteoporoticos);
                    setInhalador(entrevistaData.inhalador);
                    setProtectorEstomago(entrevistaData.protectorEstomago);
                    setOtrosMedicacion(entrevistaData.otrosMedicacion);
                    setVenredi(entrevistaData.venredi);
                    setTerapiaOcupacional(entrevistaData.terapiaOcupacional);
                })
                .catch(error => {
                    console.error('Error fetching valuation data:', error);
                });
        }
    }, [idEntrevista, paciente]);

    const formatearFecha = (date) => {
        const formattedDate = new Date(date);
        const day = String(formattedDate.getDate()).padStart(2, '0');
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = formattedDate.getFullYear();
        const fecha = `${day}-${month}-${year}`;

        return fecha;
    }

    const handleCreate = () => {
        axios.post(UPDATE_URL + paciente, {
            fumador: numFumador,
            tiempoFumador: parseInt(tiempoFumador),
            aniosDeshabilitacion: parseInt(aniosDeshabilitacion),
            alcohol: numAlcohol,
            tiempoAlcohol: parseInt(tiempoAlcohol),
            aniosDeshabilitacionAlcohol: aniosDeshabilitacionAlcohol,
            caidas12Meses: caidas12Meses,
            numCaidas: parseInt(numCaidas),
            ayudaLocomocion: ayudaLocomocion,
            protesisArticulares: protesisArticulares,
            protesisRodilla: protesisRodilla,
            protesisCadera: protesisCadera,
            protesisHombro: protesisHombro,
            protesisManoDedos: protesisManoDedos,
            protesisOtros: protesisOtros,
            vertigos: vertigos,
            mareosAndar: mareosAndar,
            cardiovascular: cardiovascular,
            hipertension: hipertension,
            insuficienciaCardiaca: insuficienciaCardiaca,
            accCerebrovascular: accCerebrovascular,
            anginaPecho: anginaPecho,
            infarto: infarto,
            trombosis: trombosis,
            otrosCardiovascular: otrosCardiovascular,
            respiratorio: respiratorio,
            asma: asma,
            epoc: epoc,
            otroRespiratorio: otrosRespiratorio,
            genitoUrinario: genitoUrinario,
            infeccionUrinaria: infeccionUrinaria,
            calculosRenales: calculosRenales,
            insuficienciaRenal: insuficienciaRenal,
            incotinenciaUrinaria: incontinenciaUrinaria,
            prostata: prostata,
            otrosGenitoUrinario: otrosGenitoUrinario,
            endocrino: endocrino,
            diabetes: diabetes,
            hipotiroidismo: hipotiroidismo,
            hipercolesterolemia: hipercolesterolemia,
            osteoporosis: osteoporosis,
            otrosEndocrino: otrosEndocrino,
            digestivo: digestivo,
            herniaHiato: herniaHiato,
            reflujo: reflujo,
            pancreatitis: pancreatitis,
            enfermedadHepatica: enfermedadHepatica,
            diverticulitis: diverticulitis,
            otrosDigestivo: otrosDigestivo,
            musculoEsqueletico: musculoEsqueletico,
            pieTobillo: pieTobillo,
            rodilla: rodilla,
            cadera: cadera,
            lumbar: lumbar,
            dorsal: dorsal,
            cervical: cervical,
            cabeza: cabeza,
            hombro: hombro,
            codo: codo,
            mano_munieca: manoMunieca,
            nervioso: nervioso,
            depresion: depresion,
            ansiedad: ansiedad,
            esclerosisMultiple: esclerosisMultiple,
            parkinson: parkinson,
            alzheimer: +alzheimer,
            deterioroCognitivo: deterioroCognitivo,
            neuropatiaPeriferica: neuropatiaPeriferica,
            otrosNervioso: otrosNervioso,
            sentidos: sentidos,
            hipoacusia: hipoacusia,
            tinitus: tinitus,
            perdidaVision: perdidaVision,
            datosInteres: datosInteres,
            medicacion: medicacion,
            antihipertensivos: antihipertensivos,
            antidiabeticos: antidiabeticos,
            estatinas: estatinas,
            anticoagulantes: anticoagulantes,
            tiroides: tiroides,
            diuretico: diuretico,
            analgesicos: analgesicos,
            antidepresivos: antidepresivos,
            ansioliticos: ansioliticos,
            antiosteoporoticos: antiosteoporoticos,
            inhalador: inhalador,
            protectorEstomago: protectorEstomago,
            otrosMedicacion: otrosMedicacion,
            venredi: venredi,
            terapiaOcupacional: terapiaOcupacional,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setOpenCrear(true);
            window.location.href = "/home";
        })
            .catch(error => {
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    const handleEdit = () => {
        setOriginalValues({
            codigo,
            nombre,
            apellidos,
            telefono,
            dateBirth,
            numViven,
            numGrupo,
            numNivelEducacional,
            numSexo,
            edad,
            numFumador,
            tiempoFumador,
            aniosDeshabilitacion,
            numAlcohol,
            tiempoAlcohol,
            aniosDeshabilitacionAlcohol,
            caidas12Meses,
            numCaidas,
            ayudaLocomocion,
            protesisArticulares,
            protesisRodilla,
            protesisCadera,
            protesisHombro,
            protesisManoDedos,
            protesisOtros,
            vertigos,
            mareosAndar,
            cardiovascular,
            hipertension,
            insuficienciaCardiaca,
            accCerebrovascular,
            anginaPecho,
            infarto,
            trombosis,
            otrosCardiovascular,
            respiratorio,
            asma,
            epoc,
            otrosRespiratorio,
            genitoUrinario,
            infeccionUrinaria,
            calculosRenales,
            insuficienciaRenal,
            incontinenciaUrinaria,
            prostata,
            otrosGenitoUrinario,
            endocrino,
            diabetes,
            hipotiroidismo,
            hipercolesterolemia,
            osteoporosis,
            otrosEndocrino,
            digestivo,
            herniaHiato,
            reflujo,
            pancreatitis,
            enfermedadHepatica,
            diverticulitis,
            otrosDigestivo,
            musculoEsqueletico,
            pieTobillo,
            rodilla,
            cadera,
            lumbar,
            dorsal,
            cervical,
            cabeza,
            hombro,
            codo,
            manoMunieca,
            nervioso,
            depresion,
            ansiedad,
            esclerosisMultiple,
            parkinson,
            alzheimer,
            deterioroCognitivo,
            neuropatiaPeriferica,
            otrosNervioso,
            sentidos,
            hipoacusia,
            tinitus,
            perdidaVision,
            datosInteres,
            medicacion,
            antihipertensivos,
            antidiabeticos,
            estatinas,
            anticoagulantes,
            tiroides,
            diuretico,
            analgesicos,
            antidepresivos,
            ansioliticos,
            antiosteoporoticos,
            inhalador,
            protectorEstomago,
            otrosMedicacion,
            venredi,
            terapiaOcupacional,
        });


        setIsEditing(true);
    };

    function convertirFecha(fechaStr) {
        // Separa la cadena en partes (día, mes y año)
        const [dia, mes, anio] = fechaStr.split('-').map(Number);

        // Los meses en JavaScript están indexados desde 0 (enero) hasta 11 (diciembre)
        const mesIndexado = mes - 1;

        // Crea un nuevo objeto Date (año, mes, día)
        const fecha = new Date(anio, mesIndexado, dia);

        return fecha;
    }

    const handleSave = () => {
        axios.put(UPDATE_PACIENTE + paciente, {
            codHistorico: codigo,
            firstname: nombre,
            lastname: apellidos,
            telefono: telefono,
            dateBirth: convertirFecha(dateBirth),
            viven: numViven,
            grupo: numGrupo,
            nivelEducacional: numNivelEducacional,
            sexo: numSexo
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(() => {
                axios.put(UPDATE_URL + codigoEntrevista, {
                    edad: parseInt(edad),
                    fumador: numFumador,
                    tiempoFumador: parseInt(tiempoFumador),
                    aniosDeshabilitacion: parseInt(aniosDeshabilitacion),
                    alcohol: numAlcohol,
                    tiempoAlcohol: parseInt(tiempoAlcohol),
                    aniosDeshabilitacionAlcohol: parseInt(aniosDeshabilitacionAlcohol),
                    caidas12Meses,
                    numCaidas: parseInt(numCaidas),
                    ayudaLocomocion,
                    protesisArticulares,
                    protesisRodilla,
                    protesisCadera,
                    protesisHombro,
                    protesisManoDedos,
                    protesisOtros,
                    vertigos,
                    mareosAndar,
                    cardiovascular,
                    hipertension,
                    insuficienciaCardiaca,
                    accCerebrovascular,
                    anginaPecho,
                    infarto,
                    trombosis,
                    otrosCardiovascular,
                    respiratorio,
                    asma,
                    epoc,
                    otrosRespiratorio,
                    genitoUrinario,
                    infeccionUrinaria,
                    calculosRenales,
                    insuficienciaRenal,
                    incontinenciaUrinaria,
                    prostata,
                    otrosGenitoUrinario,
                    endocrino,
                    diabetes,
                    hipotiroidismo,
                    hipercolesterolemia,
                    osteoporosis,
                    otrosEndocrino,
                    digestivo,
                    herniaHiato,
                    reflujo,
                    pancreatitis,
                    enfermedadHepatica,
                    diverticulitis,
                    otrosDigestivo,
                    musculoEsqueletico,
                    pieTobillo,
                    rodilla,
                    cadera,
                    lumbar,
                    dorsal,
                    cervical,
                    cabeza,
                    hombro,
                    codo,
                    manoMunieca,
                    nervioso,
                    depresion,
                    ansiedad,
                    esclerosisMultiple,
                    parkinson,
                    alzheimer,
                    deterioroCognitivo,
                    neuropatiaPeriferica,
                    otrosNervioso,
                    sentidos,
                    hipoacusia,
                    tinitus,
                    perdidaVision,
                    datosInteres,
                    medicacion,
                    antihipertensivos,
                    antidiabeticos,
                    estatinas,
                    anticoagulantes,
                    tiroides,
                    diuretico,
                    analgesicos,
                    antidepresivos,
                    ansioliticos,
                    antiosteoporoticos,
                    inhalador,
                    protectorEstomago,
                    otrosMedicacion,
                    venredi,
                    terapiaOcupacional,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(() => {
                        setIsEditing(false);
                        setOpenGood(true);
                    })
            })
            .catch(error => {
                console.error('Error updating capacity data:', error);
            });
    };

    const handleCancel = () => {
        setCodigo(originalValues.codigo);
        setCreatedAt(originalValues.createdAt);
        setNombre(originalValues.nombre);
        setApellidos(originalValues.apellidos);
        setTelefono(originalValues.telefono);
        setDateBirth(originalValues.dateBirth);
        setNumViven(originalValues.viven);
        setNumGrupo(originalValues.grupo);
        setNumNivelEducacional(originalValues.nivelEducacional);
        setNumSexo(originalValues.sexo);
        setEdad(originalValues.edad);
        setNumFumador(originalValues.fumador);
        setTiempoFumador(originalValues.tiempoFumador);
        setAniosDeshabilitacion(originalValues.aniosDeshabilitacion);
        setNumAlcohol(originalValues.alcohol);
        setTiempoAlcohol(originalValues.tiempoAlcohol);
        setAniosDeshabilitacionAlcohol(originalValues.aniosDeshabilitacionAlcohol);
        setCaidas12Meses(originalValues.caidas12Meses);
        setNumCaidas(originalValues.numCaidas);
        setAyudaLocomocion(originalValues.ayudaLocomocion);
        setProtesisArticulares(originalValues.protesisArticulares);
        setProtesisRodilla(originalValues.protesisRodilla);
        setProtesisCadera(originalValues.protesisCadera);
        setProtesisHombro(originalValues.protesisHombro);
        setProtesisManoDedos(originalValues.protesisManoDedos);
        setProtesisOtros(originalValues.protesisOtros);
        setVertigos(originalValues.vertigos);
        setMareosAndar(originalValues.mareosAndar);
        setCardiovascular(originalValues.cardiovascular);
        setHipertension(originalValues.hipertension);
        setInsuficienciaCardiaca(originalValues.insuficienciaCardiaca);
        setAccCerebrovascular(originalValues.accCerebrovascular);
        setAnginaPecho(originalValues.anginaPecho);
        setInfarto(originalValues.infarto);
        setTrombosis(originalValues.trombosis);
        setOtrosCardiovascular(originalValues.otrosCardiovascular);
        setRespiratorio(originalValues.respiratorio);
        setAsma(originalValues.asma);
        setEpoc(originalValues.epoc);
        setOtrosRespiratorio(originalValues.otrosRespiratorio);
        setGenitoUrinario(originalValues.genitoUrinario);
        setInfeccionUrinaria(originalValues.infeccionUrinaria);
        setCalculosRenales(originalValues.calculosRenales);
        setInsuficienciaRenal(originalValues.insuficienciaRenal);
        setIncontinenciaUrinaria(originalValues.incontinenciaUrinaria);
        setProstata(originalValues.prostata);
        setOtrosGenitoUrinario(originalValues.otrosGenitoUrinario);
        setEndocrino(originalValues.endocrino);
        setDiabetes(originalValues.diabetes);
        setHipotiroidismo(originalValues.hipotiroidismo);
        setHipercolesterolemia(originalValues.hipercolesterolemia);
        setOsteoporosis(originalValues.osteoporosis);
        setOtrosEndocrino(originalValues.otrosEndocrino);
        setDigestivo(originalValues.digestivo);
        setHerniaHiato(originalValues.herniaHiato);
        setReflujo(originalValues.reflujo);
        setPancreatitis(originalValues.pancreatitis);
        setEnfermedadHepatica(originalValues.enfermedadHepatica);
        setDiverticulitis(originalValues.diverticulitis);
        setOtrosDigestivo(originalValues.otrosDigestivo);
        setMusculoEsqueletico(originalValues.musculoEsqueletico);
        setPieTobillo(originalValues.pieTobillo);
        setRodilla(originalValues.rodilla);
        setCadera(originalValues.cadera);
        setLumbar(originalValues.lumbar);
        setDorsal(originalValues.dorsal);
        setCervical(originalValues.cervical);
        setCabeza(originalValues.cabeza);
        setHombro(originalValues.hombro);
        setCodo(originalValues.codo);
        setManoMunieca(originalValues.manoMunieca);
        setNervioso(originalValues.nervioso);
        setDepresion(originalValues.depresion);
        setAnsiedad(originalValues.ansiedad);
        setEsclerosisMultiple(originalValues.esclerosisMultiple);
        setParkinson(originalValues.parkinson);
        setAlzheimer(originalValues.alzheimer);
        setDeterioroCognitivo(originalValues.deterioroCognitivo);
        setNeuropatiaPeriferica(originalValues.neuropatiaPeriferica);
        setOtrosNervioso(originalValues.otrosNervioso);
        setSentidos(originalValues.sentidos);
        setHipoacusia(originalValues.hipoacusia);
        setTinitus(originalValues.tinitus);
        setPerdidaVision(originalValues.perdidaVision);
        setDatosInteres(originalValues.datosInteres);
        setMedicacion(originalValues.medicacion);
        setAntihipertensivos(originalValues.antihipertensivos);
        setAntidiabeticos(originalValues.antidiabeticos);
        setEstatinas(originalValues.estatinas);
        setAnticoagulantes(originalValues.anticoagulantes);
        setTiroides(originalValues.tiroides);
        setDiuretico(originalValues.diuretico);
        setAnalgesicos(originalValues.analgesicos);
        setAntidepresivos(originalValues.antidepresivos);
        setAnsioliticos(originalValues.ansioliticos);
        setAntiosteoporoticos(originalValues.antiosteoporoticos);
        setInhalador(originalValues.inhalador);
        setProtectorEstomago(originalValues.protectorEstomago);
        setOtrosMedicacion(originalValues.otrosMedicacion);
        setVenredi(originalValues.venredi);
        setTerapiaOcupacional(originalValues.terapiaOcupacional);

        setIsEditing(false);
    };

    const handleExportPDF = () => {
        setIsEditing(false);
        const input1 = document.getElementById('pdf-content');
        const input2 = document.getElementById('pdf-content-2');
        const input3 = document.getElementById('pdf-content-3');

        html2canvas(input1, {
            scale: 2,
            backgroundColor: null,
            useCORS: true,
            ignoreElements: (element) => element.tagName === 'BUTTON',
        }).then(canvas1 => {
            const imgData1 = canvas1.toDataURL('image/png');

            html2canvas(input2, {
                scale: 2,
                backgroundColor: null,
                useCORS: true,
                ignoreElements: (element) => element.tagName === 'BUTTON',
            }).then(canvas2 => {
                const imgData2 = canvas2.toDataURL('image/png');

                html2canvas(input3, {
                    scale: 2,
                    backgroundColor: null,
                    useCORS: true,
                    ignoreElements: (element) => element.tagName === 'BUTTON',
                }).then(canvas3 => {
                    const imgData3 = canvas3.toDataURL('image/png');

                    const pdf = new jsPDF();
                    const imgProperties1 = pdf.getImageProperties(imgData1);
                    const imgProperties2 = pdf.getImageProperties(imgData2);
                    const imgProperties3 = pdf.getImageProperties(imgData3);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;
                    const pdfHeight2 = (imgProperties2.height * pdfWidth) / imgProperties2.width;
                    const pdfHeight3 = (imgProperties3.height * pdfWidth) / imgProperties3.width;

                    pdf.addImage(imgData1, 'PNG', 0, 0, pdfWidth, pdfHeight1);
                    pdf.addPage();
                    pdf.addImage(imgData2, 'PNG', 0, 0, pdfWidth, pdfHeight2);
                    pdf.addPage();
                    pdf.addImage(imgData3, 'PNG', 0, 0, pdfWidth, pdfHeight3);

                    pdf.save(`entrevista_clinica_${nombre}${apellidos}_${createdAt}.pdf`);
                }).catch(error => {
                    console.error('Error exporting PDF:', error);
                });
            }).catch(error => {
                console.error('Error exporting PDF:', error);
            });
        }).catch(error => {
            console.error('Error exporting PDF:', error);
        });
    };



    const handleDelete = () => {
        if (window.confirm('¿Está seguro de que desea eliminar este informe?')) {
            axios.delete(DELETE_URL + codigoEntrevista, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log('Informe eliminado:', response.data);
                    window.location.href = "/home";
                    // Redirect or update the state as needed
                })
                .catch(error => {
                    console.error('Error deleting the report:', error);
                });
        }
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
            <Box sx={{ maxWidth: 1000, width: '100%', backgroundColor: 'white', padding: 2 }}>
                <Box id="pdf-content" sx={{ padding: 2 }}>
                    <AppBar position="static" sx={{ marginBottom: 4, marginTop: 8, boxShadow: 'none', border: 'none' }}>
                        <Toolbar style={{ backgroundColor: 'white' }}>
                            <img src={logoUsal} alt="Logo Izquierda" style={{ height: '70px', width: 'auto', marginRight: 'auto' }} />
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>ENTREVISTA CLÍNICA</Typography>
                            <img src={logoCiencias} alt="Logo Derecha" style={{ height: '70px', width: 'auto', marginLeft: 'auto' }} />
                        </Toolbar>
                    </AppBar>
                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>DATOS DEL PACIENTE</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">CÓDIGO</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={codigo}
                                onChange={handleInputChange(setCodigo)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">FECHA</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={createdAt}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">NOMBRE</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={nombre}
                                onChange={handleInputChange(setNombre)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">APELLIDOS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={apellidos}
                                onChange={handleInputChange(setApellidos)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TELÉFONO</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={telefono}
                                onChange={handleInputChange(setTelefono)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">FECHA DE NACIMIENTO (dd-mm-yyyy)</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={dateBirth}
                                onChange={handleInputChange(setDateBirth)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ padding: 1 }}>
                                <InputLabel id="demo-simple-select-label">VIVEN</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Viven"
                                    value={numViven}
                                    disabled={!isEditing}
                                    onChange={(e) => setNumViven(e.target.value)}
                                >
                                    <MenuItem value={0}>Solo</MenuItem>
                                    <MenuItem value={1}>Pareja</MenuItem>
                                    <MenuItem value={2}>Familiares</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ padding: 1 }}>
                                <InputLabel id="demo-simple-select-label">GRUPO</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="GRUPO"
                                    value={numGrupo}
                                    disabled={!isEditing}
                                    onChange={(e) => setNumGrupo(e.target.value)}
                                >
                                    <MenuItem value={1}>Pizarrales</MenuItem>
                                    <MenuItem value={2}>Amis</MenuItem>
                                    <MenuItem value={3}>Tierra charra</MenuItem>
                                    <MenuItem value={4}>María Auxiliadora</MenuItem>
                                    <MenuItem value={5}>Juan de la Fuente</MenuItem>
                                    <MenuItem value={6}>Buenos aires</MenuItem>
                                    <MenuItem value={7}>Tejares</MenuItem>
                                    <MenuItem value={8}>Chamberí</MenuItem>
                                    <MenuItem value={9}>San Isidro</MenuItem>
                                    <MenuItem value={10}>Puente Ladrillo</MenuItem>
                                    <MenuItem value={11}>La Vega</MenuItem>
                                    <MenuItem value={12}>San José</MenuItem>
                                    <MenuItem value={13}>Otro</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ padding: 1 }}>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ padding: 1 }}>
                                <InputLabel id="demo-simple-select-label">NIVEL EDUCACIONAL</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="NIVEL EDUCACIONAL"
                                    value={numNivelEducacional}
                                    disabled={!isEditing}
                                    onChange={(e) => setNumNivelEducacional(e.target.value)}
                                >
                                    <MenuItem value={0}>Analfabeto</MenuItem>
                                    <MenuItem value={1}>Estudios primarios incompletos</MenuItem>
                                    <MenuItem value={2}>Estudios primarios</MenuItem>
                                    <MenuItem value={3}>Estudios secundarios</MenuItem>
                                    <MenuItem value={4}>Otros</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ padding: 1 }}>
                                <InputLabel id="demo-simple-select-label">SEXO</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="SEXO"
                                    value={numSexo}
                                    disabled={!isEditing}
                                    onChange={(e) => setNumSexo(e.target.value)}
                                >
                                    <MenuItem value={1}>Hombre</MenuItem>
                                    <MenuItem value={2}>Mujer</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">EDAD</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={edad}
                                onChange={handleInputChange(setEdad)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ padding: 1 }}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={venredi} disabled={!isEditing} onChange={(e) => setVenredi(e.target.checked)} />}
                                label="VENREDI"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={terapiaOcupacional} disabled={!isEditing} onChange={(e) => setTerapiaOcupacional(e.target.checked)} />}
                                label="TERAPIA OCUPACIONAL"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>HÁBITOS NOCIVOS</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ padding: 1 }}>
                                <InputLabel id="fumador-label">FUMADOR</InputLabel>
                                <Select
                                    labelId="fumador-label"
                                    id="fumador-select"
                                    label="FUMADOR"
                                    value={numFumador}
                                    disabled={!isEditing}
                                    onChange={(e) => setNumFumador(e.target.value)}
                                >
                                    <MenuItem value={1}>No</MenuItem>
                                    <MenuItem value={2}>Sí</MenuItem>
                                    <MenuItem value={3}>Fue fumador</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO FUMADOR (años)</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={tiempoFumador}
                                onChange={handleInputChange(setTiempoFumador)}
                                InputProps={{ readOnly: !isEditing || numFumador !== 3 }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">AÑOS DE DESHABILITACIÓN (años)</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={aniosDeshabilitacion}
                                onChange={handleInputChange(setAniosDeshabilitacion)}
                                InputProps={{ readOnly: !isEditing || numFumador !== 3 }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth sx={{ padding: 1 }}>
                                <InputLabel id="fumador-label">ALCOHOL</InputLabel>
                                <Select
                                    labelId="fumador-label"
                                    id="fumador-select"
                                    label="ALCOHOL"
                                    value={numAlcohol}
                                    disabled={!isEditing}
                                    onChange={(e) => setNumAlcohol(e.target.value)}
                                >
                                    <MenuItem value={1}>No</MenuItem>
                                    <MenuItem value={2}>Sí</MenuItem>
                                    <MenuItem value={3}>Consumía Alcohol</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO ALCOHOL (años)</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={tiempoAlcohol}
                                onChange={handleInputChange(setTiempoAlcohol)}
                                InputProps={{ readOnly: !isEditing || numAlcohol !== 3 }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">AÑOS DE DESHABILITACIÓN (años)</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={aniosDeshabilitacionAlcohol}
                                onChange={handleInputChange(setAniosDeshabilitacionAlcohol)}
                                InputProps={{ readOnly: !isEditing || numAlcohol !== 3 }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>HISTORIAL</Typography>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Caídas</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">CAÍDAS EN LOS ÚLTIMOS 12 MESES</Typography>
                            </Box>
                            <Checkbox checked={caidas12Meses} value={caidas12Meses} disabled={!isEditing} />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">NÚMERO DE CAÍDAS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={numCaidas}
                                onChange={handleInputChange(setNumCaidas)}
                                InputProps={{ readOnly: !isEditing || !caidas12Meses }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={ayudaLocomocion} disabled={!isEditing} onChange={(e) => setAyudaLocomocion(e.target.checked)} />}
                                label="AYUDA LOCOMOCIÓN"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box id="pdf-content-2" sx={{ padding: 2 }}>
                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Prótesis Articulares</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={protesisArticulares} disabled={!isEditing} onChange={(e) => setProtesisArticulares(e.target.checked)} />}
                                label="PRÓTESIS ARTICULARES"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={protesisRodilla} disabled={!isEditing || !protesisArticulares} onChange={(e) => setProtesisRodilla(e.target.checked)} />}
                                label="PRÓTESIS DE RODILLA"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={protesisCadera} disabled={!isEditing || !protesisArticulares} onChange={(e) => setProtesisCadera(e.target.checked)} />}
                                label="PRÓTESIS DE CADERA"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={protesisHombro} disabled={!isEditing || !protesisArticulares} onChange={(e) => setProtesisHombro(e.target.checked)} />}
                                label="PRÓTESIS DE HOMBRO"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={protesisManoDedos} disabled={!isEditing || !protesisArticulares} onChange={(e) => setProtesisManoDedos(e.target.checked)} />}
                                label="PRÓTESIS DE MANOS O DEDOS"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">OTROS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={protesisOtros}
                                onChange={handleInputChange(setProtesisOtros)}
                                InputProps={{ readOnly: !isEditing || !caidas12Meses }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Vértigos o mareos</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={vertigos} disabled={!isEditing} onChange={(e) => setVertigos(e.target.checked)} />}
                                label="VÉRTIGOS"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={<Checkbox checked={mareosAndar} disabled={!isEditing} onChange={(e) => setMareosAndar(e.target.checked)} />}
                                label="MAREOS AL ANDAR"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema cardiovascular</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={cardiovascular} disabled={!isEditing} onChange={(e) => setCardiovascular(e.target.checked)} />}
                                label="SISTEMA CARDIOVASCULAR"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={hipertension} disabled={!isEditing || !cardiovascular} onChange={(e) => setHipertension(e.target.checked)} />}
                                label="HIPERTENSIÓN"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={insuficienciaCardiaca} disabled={!isEditing || !cardiovascular} onChange={(e) => setInsuficienciaCardiaca(e.target.checked)} />}
                                label="INSUFICICENCIA CARDIACA"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={accCerebrovascular} disabled={!isEditing || !cardiovascular} onChange={(e) => setAccCerebrovascular(e.target.checked)} />}
                                label="ACCIDENTE CEREBROVASCULAR"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={anginaPecho} disabled={!isEditing || !cardiovascular} onChange={(e) => setAnginaPecho(e.target.checked)} />}
                                label="ANGINA DE PECHO"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={infarto} disabled={!isEditing || !cardiovascular} onChange={(e) => setInfarto(e.target.checked)} />}
                                label="INFARTO"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={trombosis} disabled={!isEditing || !cardiovascular} onChange={(e) => setTrombosis(e.target.checked)} />}
                                label="TROMBOSIS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">OTROS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={otrosCardiovascular}
                                onChange={handleInputChange(setOtrosCardiovascular)}
                                InputProps={{ readOnly: !isEditing || !cardiovascular }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema respiratorio</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={respiratorio} disabled={!isEditing} onChange={(e) => setRespiratorio(e.target.checked)} />}
                                label="SISTEMA RESPIRATORIO"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={asma} disabled={!isEditing || !respiratorio} onChange={(e) => setAsma(e.target.checked)} />}
                                label="ASMA"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={epoc} disabled={!isEditing || !respiratorio} onChange={(e) => setEpoc(e.target.checked)} />}
                                label="EPOC"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">OTROS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={otrosRespiratorio}
                                onChange={handleInputChange(setOtrosRespiratorio)}
                                InputProps={{ readOnly: !isEditing || !respiratorio }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema Genitourinario</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={genitoUrinario} disabled={!isEditing} onChange={(e) => setGenitoUrinario(e.target.checked)} />}
                                        label="SISTEMA GENITOURINARIO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={infeccionUrinaria} disabled={!isEditing || !genitoUrinario} onChange={(e) => setInfeccionUrinaria(e.target.checked)} />}
                                        label="INFECCIÓN URINARIA"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={calculosRenales} disabled={!isEditing || !genitoUrinario} onChange={(e) => setCalculosRenales(e.target.checked)} />}
                                        label="CÁLCULOS RENALES"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={insuficienciaRenal} disabled={!isEditing || !genitoUrinario} onChange={(e) => setInsuficienciaRenal(e.target.checked)} />}
                                        label="INSUFICIENCIA RENAL"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={incontinenciaUrinaria} disabled={!isEditing || !genitoUrinario} onChange={(e) => setIncontinenciaUrinaria(e.target.checked)} />}
                                        label="INCONTINENCIA URINARIA"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={prostata} disabled={!isEditing || !genitoUrinario} onChange={(e) => setProstata(e.target.checked)} />}
                                        label="PRÓSTATA"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                        <Typography variant="subtitle2" color="white">OTROS</Typography>
                                    </Box>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={otrosGenitoUrinario}
                                        onChange={handleInputChange(setOtrosGenitoUrinario)}
                                        InputProps={{ readOnly: !isEditing || !genitoUrinario }}
                                        sx={{
                                            border: isEditing ? '1px solid #ccc' : 'none',
                                            fontSize: 'small',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                        }}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema Endocrino</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={endocrino} disabled={!isEditing} onChange={(e) => setEndocrino(e.target.checked)} />}
                                        label="SISTEMA ENDOCRINO"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={diabetes} disabled={!isEditing || !endocrino} onChange={(e) => setDiabetes(e.target.checked)} />}
                                        label="DIABETES"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={hipotiroidismo} disabled={!isEditing || !endocrino} onChange={(e) => setHipotiroidismo(e.target.checked)} />}
                                        label="HIPOTIROIDISMO"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={hipercolesterolemia} disabled={!isEditing || !endocrino} onChange={(e) => setHipercolesterolemia(e.target.checked)} />}
                                        label="HIPERCOLESTEROLEMIA"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={osteoporosis} disabled={!isEditing || !endocrino} onChange={(e) => setOsteoporosis(e.target.checked)} />}
                                        label="OSTEOPOROSIS"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                        <Typography variant="subtitle2" color="white">OTROS</Typography>
                                    </Box>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={otrosEndocrino}
                                        onChange={handleInputChange(setOtrosEndocrino)}
                                        InputProps={{ readOnly: !isEditing || !endocrino }}
                                        sx={{
                                            border: isEditing ? '1px solid #ccc' : 'none',
                                            fontSize: 'small',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                        }}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema Digestivo</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={digestivo} disabled={!isEditing} onChange={(e) => setDigestivo(e.target.checked)} />}
                                        label="SISTEMA DIGESTIVO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={herniaHiato} disabled={!isEditing || !digestivo} onChange={(e) => setHerniaHiato(e.target.checked)} />}
                                        label="HERNIA DE HIATO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={reflujo} disabled={!isEditing || !digestivo} onChange={(e) => setReflujo(e.target.checked)} />}
                                        label="REFLUJO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={pancreatitis} disabled={!isEditing || !digestivo} onChange={(e) => setPancreatitis(e.target.checked)} />}
                                        label="PANCREATITIS"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={enfermedadHepatica} disabled={!isEditing || !digestivo} onChange={(e) => setEnfermedadHepatica(e.target.checked)} />}
                                        label="ENFERMEDAD HEPÁTICA"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={diverticulitis} disabled={!isEditing || !digestivo} onChange={(e) => setDiverticulitis(e.target.checked)} />}
                                        label="DIVERTICULITIS"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                        <Typography variant="subtitle2" color="white">OTROS</Typography>
                                    </Box>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={otrosDigestivo}
                                        onChange={handleInputChange(setOtrosDigestivo)}
                                        InputProps={{ readOnly: !isEditing || !digestivo }}
                                        sx={{
                                            border: isEditing ? '1px solid #ccc' : 'none',
                                            fontSize: 'small',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                        }}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box id="pdf-content-3" sx={{ padding: 2 }}>
                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema Musculoesquelético</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={musculoEsqueletico} disabled={!isEditing} onChange={(e) => setMusculoEsqueletico(e.target.checked)} />}
                                        label="SISTEMA MUSCULOESQUELÉTICO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={pieTobillo} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setPieTobillo(e.target.checked)} />}
                                        label="PIE Y TOBILLO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={rodilla} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setRodilla(e.target.checked)} />}
                                        label="RODILLA"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={cadera} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setCadera(e.target.checked)} />}
                                        label="CADERA"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={lumbar} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setLumbar(e.target.checked)} />}
                                        label="LUMBAR"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={dorsal} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setDorsal(e.target.checked)} />}
                                        label="DORSAL"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={cervical} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setCervical(e.target.checked)} />}
                                        label="CERVICAL"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={cabeza} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setCabeza(e.target.checked)} />}
                                        label="CABEZA"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={hombro} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setHombro(e.target.checked)} />}
                                        label="HOMBRO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={codo} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setCodo(e.target.checked)} />}
                                        label="CODO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={manoMunieca} disabled={!isEditing || !musculoEsqueletico} onChange={(e) => setManoMunieca(e.target.checked)} />}
                                        label="MANO Y MUÑECA"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema Nervioso</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={nervioso} disabled={!isEditing} onChange={(e) => setNervioso(e.target.checked)} />}
                                        label="SISTEMA NERVIOSO"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={depresion} disabled={!isEditing || !nervioso} onChange={(e) => setDepresion(e.target.checked)} />}
                                        label="DEPRESIÓN"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={ansiedad} disabled={!isEditing || !nervioso} onChange={(e) => setAnsiedad(e.target.checked)} />}
                                        label="ANSIEDAD"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={<Checkbox checked={esclerosisMultiple} disabled={!isEditing || !nervioso} onChange={(e) => setEsclerosisMultiple(e.target.checked)} />}
                                        label="ESCLEROSIS MÚLTIPLE"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={parkinson} disabled={!isEditing || !nervioso} onChange={(e) => setParkinson(e.target.checked)} />}
                                        label="PARKINSON"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={alzheimer} disabled={!isEditing || !nervioso} onChange={(e) => setAlzheimer(e.target.checked)} />}
                                        label="ALZHEIMER"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={deterioroCognitivo} disabled={!isEditing || !nervioso} onChange={(e) => setDeterioroCognitivo(e.target.checked)} />}
                                        label="DETERIORO COGNITIVO"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={<Checkbox checked={neuropatiaPeriferica} disabled={!isEditing || !nervioso} onChange={(e) => setNeuropatiaPeriferica(e.target.checked)} />}
                                        label="NEUROPATÍA PERIFÉRICA"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                        <Typography variant="subtitle2" color="white">OTROS</Typography>
                                    </Box>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={otrosNervioso}
                                        onChange={handleInputChange(setOtrosNervioso)}
                                        InputProps={{ readOnly: !isEditing || !nervioso }}
                                        sx={{
                                            border: isEditing ? '1px solid #ccc' : 'none',
                                            fontSize: 'small',
                                            '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                        }}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Sistema de los Sentidos</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={sentidos} disabled={!isEditing} onChange={(e) => setSentidos(e.target.checked)} />}
                                label="SISTEMA DE LOS SENTIDOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={hipoacusia} disabled={!isEditing || !sentidos} onChange={(e) => setHipoacusia(e.target.checked)} />}
                                label="HIPOCAUSIA"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={tinitus} disabled={!isEditing || !sentidos} onChange={(e) => setTinitus(e.target.checked)} />}
                                label="TINITUS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={perdidaVision} disabled={!isEditing || !sentidos} onChange={(e) => setPerdidaVision(e.target.checked)} />}
                                label="PÉRDIDA DE VISIÓN"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Otros datos de interés</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">DATOS DE INTERÉS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={datosInteres}
                                onChange={handleInputChange(setDatosInteres)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Medicación</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={medicacion} disabled={!isEditing} onChange={(e) => setMedicacion(e.target.checked)} />}
                                label="MEDICACIÓN"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={antihipertensivos} disabled={!isEditing || !medicacion} onChange={(e) => setAntihipertensivos(e.target.checked)} />}
                                label="ANTIHIPERTENSIVOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={antidiabeticos} disabled={!isEditing || !medicacion} onChange={(e) => setAntidiabeticos(e.target.checked)} />}
                                label="ANTIDIABETICOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={estatinas} disabled={!isEditing || !medicacion} onChange={(e) => setEstatinas(e.target.checked)} />}
                                label="ESTATINAS PARA EL COLESTEROL"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={anticoagulantes} disabled={!isEditing || !medicacion} onChange={(e) => setAnticoagulantes(e.target.checked)} />}
                                label="ANTICOAGULANTES O ANTIAGREGARIO"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={tiroides} disabled={!isEditing || !medicacion} onChange={(e) => setTiroides(e.target.checked)} />}
                                label="TTO TIROIDES"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={diuretico} disabled={!isEditing || !medicacion} onChange={(e) => setDiuretico(e.target.checked)} />}
                                label="DIURÉTICO"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={analgesicos} disabled={!isEditing || !medicacion} onChange={(e) => setAnalgesicos(e.target.checked)} />}
                                label="ANALGÉSICOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={antidepresivos} disabled={!isEditing || !medicacion} onChange={(e) => setAntidepresivos(e.target.checked)} />}
                                label="ANTIDEPRESIVOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={ansioliticos} disabled={!isEditing || !medicacion} onChange={(e) => setAnsioliticos(e.target.checked)} />}
                                label="ANSIOLÍTICOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={antiosteoporoticos} disabled={!isEditing || !medicacion} onChange={(e) => setAntiosteoporoticos(e.target.checked)} />}
                                label="ANTIOSTEOPORÓTICOS"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={inhalador} disabled={!isEditing || !medicacion} onChange={(e) => setInhalador(e.target.checked)} />}
                                label="INHALADOR"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Checkbox checked={protectorEstomago} disabled={!isEditing || !medicacion} onChange={(e) => setProtectorEstomago(e.target.checked)} />}
                                label="PROTECTOR DE ESTÓMAGO"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">OTROS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={otrosMedicacion}
                                onChange={handleInputChange(setOtrosMedicacion)}
                                InputProps={{ readOnly: !isEditing || !medicacion }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    {isEditing ? (
                        <>
                            <Button variant="contained" color="secondary" onClick={handleCreate} sx={{ marginRight: 2 }}>Crear Entrevista</Button>
                            <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>Guardar Cambios</Button>
                            <Button variant="contained" color="error" onClick={handleDelete} sx={{ marginRight: 2 }}>Eliminar</Button>
                            <Button variant="contained" onClick={handleCancel}>Cancelar edición</Button>
                        </>
                    ) : (
                        <>
                            <Button variant="contained" color="primary" onClick={handleEdit} sx={{ marginRight: 2 }}>Editar</Button>
                            <Button variant="contained" color="secondary" onClick={handleExportPDF} sx={{ marginRight: 2 }}>Exportar PDF</Button>
                            <Button variant="contained" color="error" onClick={handleDelete}>Eliminar</Button>
                        </>
                    )}
                </Box>
                <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                    <Alert severity="success" onClose={() => setOpenGood(false)}>¡Informe actualizado!</Alert>
                </Snackbar>
                <Snackbar open={openCrear} autoHideDuration={2000} onClose={() => setOpenCrear(false)}>
                    <Alert severity="success" onClose={() => setOpenCrear(false)}>Entrevista creada!</Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default EntrevistaViewer;
