import React from 'react';
import { TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Checkbox, Alert, Snackbar } from '@mui/material';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/capacidad/";
const PACIENTE_URL = "https://" + config.BACKEND + ":3000/pacientes/";

const CapacidadFuncional = ({codigo}) => {
    const [codHistorico] = React.useState(codigo);
    const [tiempoPiesJuntos, setTiempoPiesJuntos] = React.useState('');
    const [tiempoPieAdelante, setTiempoPieAdelante] = React.useState('');
    const [tiempoPiesFila, setTiempoPiesFila] = React.useState('');
    const [tiempoDesplazamiento, setTiempoDesplazamiento] = React.useState('');
    const [tiempoPruebaFuerza, setTiempoPruebaFuerza] = React.useState('');
    const [velocidad, setVelocidad] = React.useState('');
    const [potencia, setPotencia] = React.useState('');
    const [distancia, setDistancia] = React.useState('');
    const [metrosAtras1, setMetrosAtras1] = React.useState('');
    const [metrosAtras2, setMetrosAtras2] = React.useState('');
    const [metrosAtras3, setMetrosAtras3] = React.useState('');
    const [pasosCuadrados1, setPasosCuadrados1] = React.useState('');
    const [pasosCuadrados2, setPasosCuadrados2] = React.useState('');
    const [pasosCuadrados3, setPasosCuadrados3] = React.useState('');
    const [falloEjecucion, setFalloEjecucion] = React.useState(false);

    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const guardarDatos = () => {
        let paciente;
        let userId;
        let pacienteId;

        axios.get(PACIENTE_URL + codHistorico, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                paciente = response.data;

                userId = localStorage.getItem("id");
                pacienteId = paciente.id.toString();

                axios.post(API_URL + pacienteId + "/" + userId, {
                    tiempoPiesJuntos: parseFloat(tiempoPiesJuntos).toFixed(2),
                    tiempoPieAdelante: parseFloat(tiempoPieAdelante).toFixed(2),
                    tiempoPiesFila: parseFloat(tiempoPiesFila).toFixed(2),
                    tiempoDesplazamiento: parseFloat(tiempoDesplazamiento).toFixed(2),
                    tiempoPruebaFuerza: parseFloat(tiempoPruebaFuerza).toFixed(2),
                    velocidad: parseFloat(velocidad).toFixed(2),
                    potencia: parseFloat(potencia).toFixed(2),
                    distancia: parseFloat(distancia).toFixed(2),
                    metrosAtras1: parseFloat(metrosAtras1),
                    metrosAtras2: parseFloat(metrosAtras2),
                    metrosAtras3: parseFloat(metrosAtras3),
                    pasosCuadrados1: parseFloat(pasosCuadrados1).toFixed(2),
                    pasosCuadrados2: parseFloat(pasosCuadrados2).toFixed(2),
                    pasosCuadrados3: parseFloat(pasosCuadrados3).toFixed(2),
                    falloEjecucion: falloEjecucion
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    setOpenGood(true);
                    window.location.href = "/home";
                })
            })
            .catch(error => {
                setOpenError(true);
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img
                            src={logoUsal}
                            alt="Logo Izquierda"
                            style={{ height: '100px', width: 'auto', marginRight: 'auto' }}
                        />
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}
                        >
                            CAPACIDAD FUNCIONAL
                        </Typography>
                        <img
                            src={logoCiencias}
                            alt="Logo Derecha"
                            style={{ height: '100px', width: 'auto', marginLeft: 'auto' }}
                        />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <TextField
                        label="Código del Paciente"
                        required
                        variant="outlined"
                        sx={{ width: '25%', marginBottom: 4 }}
                        value={codHistorico}
                    />

                    <Typography variant="h6" gutterBottom>
                        SPPB
                    </Typography>
                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Prueba de Equilibrio
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tiempo Pies Juntos (segundos)" variant="outlined" value={tiempoPiesJuntos} onChange={(e) => setTiempoPiesJuntos(e.target.value)} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tiempo Pie Adelante (segundos)" variant="outlined" value={tiempoPieAdelante} onChange={(e) => setTiempoPieAdelante(e.target.value)} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tiempo Pies Fila (segundos)" variant="outlined" value={tiempoPiesFila} onChange={(e) => setTiempoPiesFila(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Prueba de Desplazamiento
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Tiempo (segundos)" variant="outlined" sx={{ width: '25%' }} value={tiempoDesplazamiento} onChange={(e) => setTiempoDesplazamiento(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ marginBottom: 4 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Prueba de Fuerza
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Tiempo (segundos)" variant="outlined" sx={{ width: '25%' }} value={tiempoPruebaFuerza} onChange={(e) => setTiempoPruebaFuerza(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        ENCODER
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Velocidad (m/s)" variant="outlined" value={velocidad} onChange={(e) => setVelocidad(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Potencia (w)" variant="outlined" value={potencia} onChange={(e) => setPotencia(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Distancia (cm)" variant="outlined" value={distancia} onChange={(e) => setDistancia(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        3 METROS MARCHA ATRÁS
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Medida 1" variant="outlined" value={metrosAtras1} onChange={(e) => setMetrosAtras1(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Medida 2" variant="outlined" value={metrosAtras2} onChange={(e) => setMetrosAtras2(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Medida 3" variant="outlined" value={metrosAtras3} onChange={(e) => setMetrosAtras3(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        4 PASOS CUADRADOS
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField fullWidth label="Medida 1" variant="outlined" value={pasosCuadrados1} onChange={(e) => setPasosCuadrados1(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="Medida 2" variant="outlined" value={pasosCuadrados2} onChange={(e) => setPasosCuadrados2(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="Medida 3" variant="outlined" value={pasosCuadrados3} onChange={(e) => setPasosCuadrados3(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox value={falloEjecucion} />
                                <Typography variant="body1">Fallo en Ejecución</Typography>
                            </Box>
                        </Grid>
                        <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                            <Alert severity="success" onClose={() => setOpenGood(false)}>¡Informe creado correctamente!</Alert>
                        </Snackbar>
                        <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                            <Alert severity="error" onClose={() => setOpenError(false)}>No hay pacientes con ese código.</Alert>
                        </Snackbar>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Button variant="contained" color="primary" onClick={guardarDatos} onChange={(e) => setFalloEjecucion(e.target.value)}>
                            Guardar Datos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CapacidadFuncional;

