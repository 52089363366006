import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Avatar, Grid, Paper, TextField, Button, Snackbar, Alert, Link, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Axios from "axios";
import config from "../../config";
import Cookies from 'universal-cookie';

// URL de la API para la autenticación
const LOGIN_URL = "https://" + config.BACKEND + ":3000/users/login";

function Login() {
    const cookies = new Cookies();
    const navigate = useNavigate()
    const boxStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }
    const paperStyle = { padding: '20px', width: '400px' }
    const headStyle = { margin: 0 }
    const avatarStyle = { backgroundColor: '#0D6AEE' };
    const buttonStyle = { backgroundColor: '#0D6AEE' };

    // Estado para almacenar el nombre de usuario y la contraseña
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Estado para controlar la apertura de las notificaciones (Snackbar)
    const [openGood, setOpenGood] = useState(false);
    const [openBad, setOpenBad] = useState(false);
    const [openBaja, setOpenBaja] = useState(false);

    // Función para enviar la solicitud de inicio de sesión
    const handleLogin = (e) => {
        let userId;
        e.preventDefault();

        // Realizar la solicitud de inicio de sesión
        Axios.post(LOGIN_URL, {
            username: username,
            password: password
        })
            .then((response) => {
                // Si la solicitud es exitosa, mostrar una notificación de éxito
                if(response.data.message.trim() === "Tu usuario está desactivado"){
                    setOpenBaja(true);
                } else {
                    cookies.set("TOKEN", response.data.token,{
                        path: "/",
                    });

                    localStorage.setItem('id', response.data.id.toString());
                    localStorage.setItem('admin', response.data.is_admin.toString());

                    console.log(`Este es el userID: ${userId}`);

                    if(response.data.is_admin === true){
                        window.location.href = "/homeAdmin";
                    } else{
                        window.location.href = "/home";
                    }

                    setOpenGood(true);
                }
            })
            .catch((error) => {
                // Si hay un error en la solicitud, mostrar una notificación de error
                setOpenBad(true);
            });
    };

    return (
        <Box style={boxStyle}>
            <Paper elevation={20} style={paperStyle}>
                <Grid align='center'>
                    <Avatar style={avatarStyle}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <h2 style={headStyle}>Iniciar Sesión</h2>
                </Grid>
                <form>
                    <Grid container spacing={2}>
                        {/* Campo para el nombre de usuario */}
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre de usuario"
                                required
                                fullWidth
                                margin="normal"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Grid>
                        {/* Campo para la contraseña */}
                        <Grid item xs={12}>
                            <TextField
                                label="Contraseña"
                                required
                                type="password"
                                fullWidth
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        {/* Botón de inicio de sesión */}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                style={buttonStyle}
                                fullWidth
                                onClick={handleLogin}
                            >
                                Iniciar Sesión
                            </Button>
                        </Grid>
                        {/* Texto para registrarse */}
                        <Grid item xs={12}>
                            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                ¿Todavía no te has registrado?{' '}
                                <Link href="#" onClick={() => navigate('/register')}>
                                    Registrarse aquí
                                </Link>
                            </Typography>
                        </Grid>
                        {/* Notificación de éxito */}
                        <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                            <Alert severity="success" onClose={() => setOpenGood(false)}>¡Inicio de sesión exitoso!</Alert>
                        </Snackbar>
                        {/* Notificación de error */}
                        <Snackbar open={openBad} autoHideDuration={2000} onClose={() => setOpenBad(false)}>
                            <Alert severity="error" onClose={() => setOpenBad(false)}>¡Nombre de usuario o contraseña incorrectos!</Alert>
                        </Snackbar>
                        {/* Notificación de usuario dado de baja */}
                        <Snackbar open={openBaja} autoHideDuration={2000} onClose={() => setOpenBaja(false)}>
                            <Alert severity="error" onClose={() => setOpenBaja(false)}>¡El usuario está desactivado!</Alert>
                        </Snackbar>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
}

export default Login;
