import { Alert, Box, Avatar, Grid, MenuItem, Paper, Select, TextField, InputLabel, FormControl, Button, Snackbar, Typography, Link } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from "react";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import Cookies from "universal-cookie";

const URL_REGISTER = "https://" + config.BACKEND + ":3000/users/register";
const LOGIN_URL = "https://" + config.BACKEND + ":3000/users/login";


function SignUp() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const boxStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }
    const paperStyle = { padding: '20px', width: '400px' }
    const headStyle = { margin: 0 }
    const avatarStyle = { backgroundColor: '#0D6AEE' };
    const buttonStyle = { backgroundColor: '#0D6AEE' };

    const [nombre, setNombre] = React.useState('');
    const [apellidos, setApellidos] = React.useState('');
    const [usuario, setUsuario] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [profesional, setProfesional] = React.useState('');

    const [openGood, setOpenGood] = React.useState(false);
    const [openBad, setOpenBad] = React.useState(false);

    const [errorNombre, setErrorNombre] = React.useState({
        error: false,
        message: ""
    });

    const [errorApellidos, setErrorApellidos] = React.useState({
        error: false,
        message: ""
    });

    const [errorUsuario, setErrorUsuario] = React.useState({
        error: false,
        message: ""
    });

    const [errorPassword, setErrorPassword] = React.useState({
        error: false,
        message: ""
    });

    const [errorEmail, setErrorEmail] = React.useState({
        error: false,
        message: ""
    });

    const validateNombre = (nombre) => {
        const regex = /\d/;

        if (nombre.trim() === "" || regex.test(nombre)) {
            return false;
        } else {
            return true;
        }
    };

    const validateApellidos = (apellidos) => {
        const regex = /\d/;

        if (apellidos.trim() === "" || regex.test(apellidos)) {
            return false;
        } else {
            return true;
        }
    };

    const validateUsuario = (usuario) => {
        const regex = /\s/;

        if (regex.test(usuario) || usuario.trim() === "") {
            return false;
        } else {
            return true;
        }
    };

    const validatePassword = (password) => {
        const regex = /\s/;

        if ((password.trim().length < 8 || password.trim().length > 16) || regex.test(password) || password.trim() === "") {
            return false;
        } else {
            return true;
        }
    };


    const validateEmail = (email) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        return regex.test(email);
    };

    function createPost(usuario, nombre, apellidos, email, password, numProf) {

        Axios
            .post(URL_REGISTER, {
                username: usuario,
                firstname: nombre,
                lastname: apellidos,
                email: email,
                password: password,
                profesional: numProf,
                is_admin: false
            })
            .then((response) => {
                setOpenGood(true);
                Axios.post(LOGIN_URL, {
                    username: usuario,
                    password: password,
                })
                    .then((response) => {
                        cookies.set("TOKEN", response.data.token,{
                            path: "/",
                        });
    
                        localStorage.setItem('id', response.data.id.toString());
                        localStorage.setItem('admin', response.data.is_admin.toString());
    
                        if(response.data.is_admin === true){
                            window.location.href = "/homeAdmin";
                        } else{
                            window.location.href = "/home";
                        }
                    })
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let error = false;
        let numProf = 0;

        if (!validateNombre(nombre)) {
            setErrorNombre({
                error: true,
                message: "El nombre no puede tener números"
            });
            error = true;
        } else {
            setErrorNombre({
                error: false,
                message: ""
            })
        }

        if (!validateApellidos(apellidos)) {
            setErrorApellidos({
                error: true,
                message: "Los apellidos no pueden tener números"
            });
            error = true;
        } else {
            setErrorApellidos({
                error: false,
                message: ""
            })
        }

        if (!validateUsuario(usuario)) {
            setErrorUsuario({
                error: true,
                message: "El usuario no puede tener espacios en blanco"
            });
            error = true;
        } else {
            setErrorUsuario({
                error: false,
                message: ""
            })
        }

        if (!validatePassword(password)) {
            setErrorPassword({
                error: true,
                message: "La contraseña debe tener entre 8 y 16 caracteres"
            });
            error = true;
        } else {
            setErrorPassword({
                error: false,
                message: ""
            })
        }

        if (!validateEmail(email)) {
            setErrorEmail({
                error: true,
                message: "Introduce un email válido"
            });
            error = true;
        } else {
            setErrorEmail({
                error: false,
                message: ""
            })
        }

        if(profesional.trim() === "Enfermero/a"){
            numProf = 1;
        } else if(profesional.trim() === "Fisioterapeuta"){
            numProf = 2;
        } else{
            numProf = 0;
        }

        if(error === true){
            setOpenBad(true);
        } else {
            createPost(usuario, nombre, apellidos, email, password, numProf);
        }
    };


    return (
        <Box style={boxStyle}>
            <Paper elevation={20} style={paperStyle}>
                <Grid align='center'>
                    <Avatar style={avatarStyle}>
                        <AddCircleOutlineIcon />
                    </Avatar>
                    <h2 style={headStyle}>Registrarse</h2>
                </Grid>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField label="Nombre" required fullWidth margin="normal" error={errorNombre.error} helperText={errorNombre.message} value={nombre} onChange={(e) => setNombre(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Apellidos" required fullWidth margin="normal" error={errorApellidos.error} helperText={errorApellidos.message} value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Usuario" required fullWidth margin="normal" error={errorUsuario.error} helperText={errorUsuario.message} value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Email" required fullWidth margin="normal" error={errorEmail.error} helperText={errorEmail.message} value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Contraseña" required type="password" error={errorPassword.error} helperText={errorPassword.message} fullWidth margin="normal" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Profesión</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Profesion"
                                    value={profesional}
                                    onChange={(e) => setProfesional(e.target.value)}
                                >
                                    <MenuItem value={"Enfermero/a"}>Enfermero/a</MenuItem>
                                    <MenuItem value={"Fisioterapeuta"}>Fisioterapeuta</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" style={buttonStyle} onClick={handleSubmit}>Registrarse</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                ¿Ya te has registrado?{' '}
                                <Link href="#" onClick={() => navigate('/login')}>
                                    Iniciar sesión aquí
                                </Link>
                            </Typography>
                        </Grid>
                        <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                            <Alert severity="success" onClose={() => setOpenGood(false)}>¡Usuario registrado correctamente!</Alert>
                        </Snackbar>
                        <Snackbar open={openBad} autoHideDuration={2000} onClose={() => setOpenBad(false)}>
                            <Alert severity="error" onClose={() => setOpenBad(false)}>¡Este usuario ya está registrado!</Alert>
                        </Snackbar>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
}

export default SignUp;

