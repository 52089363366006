import React from 'react';
import { TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Snackbar, Alert } from '@mui/material';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/medidas/";
const PACIENTE_URL = "https://" + config.BACKEND + ":3000/pacientes/";

const MedidasAntropometricas = ({codigo}) => {
    const [codHistorico] = React.useState(codigo);
    const [talla, setTalla] = React.useState('');
    const [peso, setPeso] = React.useState('');
    const [perimetroAbdominal1, setPerimetroAbdominal1] = React.useState('');
    const [perimetroAbdominal2, setPerimetroAbdominal2] = React.useState('');
    const [perimetroAbdominal3, setPerimetroAbdominal3] = React.useState('');
    const [imc, setImc] = React.useState('');
    const [grasa, setGrasa] = React.useState('');
    const [nivelGrasa, setNivelGrasa] = React.useState('');
    const [musculo, setMusculo] = React.useState('');
    const [bd, setBd] = React.useState('');
    const [bi, setBi] = React.useState('');
    const [pd, setPd] = React.useState('');
    const [pi, setPi] = React.useState('');
    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const guardarDatos = () => {
        let paciente;
        let userId;
        let pacienteId;

        axios.get(PACIENTE_URL + codHistorico, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                paciente = response.data;
                userId = localStorage.getItem("id");
                pacienteId = paciente.id.toString();

                axios.post(API_URL + pacienteId + "/" + userId, {
                    talla: parseFloat(talla).toFixed(2),
                    peso: parseFloat(peso).toFixed(2),
                    perimetroAbdominal1: parseFloat(perimetroAbdominal1),
                    perimetroAbdominal2: parseFloat(perimetroAbdominal2),
                    perimetroAbdominal3: parseFloat(perimetroAbdominal3),
                    grasa: parseFloat(grasa).toFixed(1),
                    nivelGrasa: parseInt(nivelGrasa),
                    musculo: parseFloat(musculo).toFixed(1),
                    imc: parseFloat(imc).toFixed(2),
                    bd: parseFloat(bd),
                    bi: parseFloat(bi),
                    pd: parseFloat(pd),
                    pi: parseFloat(pi)
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    setOpenGood(true);
                    window.location.href = "/home";
                })
            })
            .catch(error => {
                setOpenError(true);
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img
                            src={logoUsal}
                            alt="Logo Izquierda"
                            style={{ height: '100px', width: 'auto', marginRight: 'auto' }}
                        />
                        <Typography
                            variant="h4"
                            component="div"
                            sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}
                        >
                            ANTROPOMETRÍA
                        </Typography>
                        <img
                            src={logoCiencias}
                            alt="Logo Derecha"
                            style={{ height: '100px', width: 'auto', marginLeft: 'auto' }}
                        />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <TextField
                        label="Código del Paciente"
                        required
                        variant="outlined"
                        sx={{ width: '25%', marginBottom: 4 }}
                        value={codHistorico}
                    />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Talla (m)" variant="outlined" value={talla} onChange={(e) => setTalla(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Peso (kg)" variant="outlined" value={peso} onChange={(e) => setPeso(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        PERÍMETRO ABDOMINAL
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Medida 1 (cm)" variant="outlined" value={perimetroAbdominal1} onChange={(e) => setPerimetroAbdominal1(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Medida 2 (cm)" variant="outlined" value={perimetroAbdominal2} onChange={(e) => setPerimetroAbdominal2(e.target.value)} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Medida 3 (cm)" variant="outlined" value={perimetroAbdominal3} onChange={(e) => setPerimetroAbdominal3(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        IMPEDANCIA
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField fullWidth label="Grasa (%)" variant="outlined" value={grasa} onChange={(e) => setGrasa(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="Nivel de grasa visceral" variant="outlined" value={nivelGrasa} onChange={(e) => setNivelGrasa(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="Músculo (%)" variant="outlined" value={musculo} onChange={(e) => setMusculo(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="IMC (k/m^2)" variant="outlined" value={imc} onChange={(e) => setImc(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
                        PESO DE BRAZO Y PIERNAS
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField fullWidth label="BD (kg)" variant="outlined" value={bd} onChange={(e) => setBd(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="BI (kg)" variant="outlined" value={bi} onChange={(e) => setBi(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="PD (kg)" variant="outlined" value={pd} onChange={(e) => setPd(e.target.value)} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField fullWidth label="PI (kg)" variant="outlined" value={pi} onChange={(e) => setPi(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>¡Datos guardados correctamente!</Alert>
                    </Snackbar>

                    <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                        <Alert severity="error" onClose={() => setOpenError(false)}>No hay pacientes con ese código.</Alert>
                    </Snackbar>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Button variant="contained" color="primary" onClick={guardarDatos}>
                            Guardar Datos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default MedidasAntropometricas;
