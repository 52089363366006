import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, TextField, Select, Typography, Box, Grid, Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import config from '../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = `https://${config.BACKEND}:3000/users/`;

const UserViewer = () => {
    const userId = localStorage.getItem('id');

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [profesional, setProfesional] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [originalValues, setOriginalValues] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [openGood, setOpenGood] = useState(false);
    const [openError, setOpenError] = useState(false);

    useEffect(() => {
        if (userId) {
            axios.get(API_URL + userId, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    setFirstname(response.data.firstname);
                    setLastname(response.data.lastname);
                    setEmail(response.data.email);
                    const professionId = response.data.profesional;
                    const profession = professionId === 1 ? "Enfermero/a" : professionId === 2 ? "Fisioterapeuta" : "";
                    setProfesional(profession);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [userId]);

    const handleEdit = () => {
        setOriginalValues({
            firstname,
            lastname,
            email,
            profesional
        });
        setIsEditing(true);
    };

    const handleSave = () => {
        if (newPassword !== confirmPassword) {
            setOpenError(true);
            return;
        }

        // Mapea el nombre de la profesión al valor numérico correspondiente
        const professionId = profesional === "Enfermero/a" ? 1 : profesional === "Fisioterapeuta" ? 2 : 0;

        axios.put(API_URL + userId, {
            firstname: firstname,
            lastname: lastname,
            email: email,
            profesional: professionId,
            password: newPassword // Utiliza la nueva contraseña
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setOpenGood(true);
                setIsEditing(false);
                window.location.href = "/home";
            })
            .catch(error => {
                console.error('Error saving changes:', error);
            });
    };

    const handleCancel = () => {
        setFirstname(originalValues.firstname);
        setLastname(originalValues.lastname);
        setEmail(originalValues.email);
        setProfesional(originalValues.profesional);
        setNewPassword('');
        setConfirmPassword('');
        setIsEditing(false);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f0f0f0', padding: 2 }}>
            <Box sx={{ maxWidth: 800, width: '100%', backgroundColor: 'white', padding: 4, borderRadius: 2 }}>
                <Typography variant="h4" sx={{ marginBottom: 2 }}>Bienvenido {firstname}</Typography>
                <Typography variant="h6" sx={{ marginBottom: 4 }}>Datos personales:</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Nombre"
                            fullWidth
                            variant="outlined"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            InputProps={{ readOnly: !isEditing }}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Apellidos"
                            fullWidth
                            variant="outlined"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            InputProps={{ readOnly: !isEditing }}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Email"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{ readOnly: !isEditing }}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Profesión</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Profesion"
                                value={profesional}
                                disabled={!isEditing}
                                onChange={(e) => setProfesional(e.target.value)}
                            >
                                <MenuItem value={"Enfermero/a"}>Enfermero/a</MenuItem>
                                <MenuItem value={"Fisioterapeuta"}>Fisioterapeuta</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Nueva Contraseña"
                            fullWidth
                            variant="outlined"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            InputProps={{ readOnly: !isEditing }}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Volver a introducir contraseña"
                            fullWidth
                            variant="outlined"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputProps={{ readOnly: !isEditing }}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    {isEditing ? (
                        <>
                            <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>Guardar Cambios</Button>
                            <Button variant="contained" onClick={handleCancel}>Cancelar edición</Button>
                        </>
                    ) : (
                        <>
                            <Button variant="contained" color="primary" onClick={handleEdit} sx={{ marginRight: 2 }}>Editar</Button>
                        </>
                    )}
                </Box>

                <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                    <Alert severity="success" onClose={() => setOpenGood(false)}>¡Datos actualizados!</Alert>
                </Snackbar>
                <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                    <Alert severity="error" onClose={() => setOpenError(false)}>Las contraseñas no coinciden.</Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default UserViewer;
