import React from 'react';
import { TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Snackbar, Alert } from '@mui/material';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/valoracion/";
const PACIENTE_URL = "https://" + config.BACKEND + ":3000/pacientes/";

const ValoracionFuncional = ({codigo}) => {
    //console.log(`El codigo que entra es: ${codigo}`);
    const [codHistorico] = React.useState(codigo);
    //console.log(`El codigo historico es: ${codHistorico}`);
    const [mediaDerecha, setMediaDerecha] = React.useState('');
    const [mediaIzquierda, setMediaIzquierda] = React.useState('');
    const [dsDerecha, setDsDerecha] = React.useState('');
    const [dsIzquierda, setDsIzquierda] = React.useState('');

    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const guardarDatos = () => {
        let pacienteId;
        let paciente;
        let userId;

        axios.get(PACIENTE_URL + codHistorico, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                paciente = response.data;
                userId = localStorage.getItem("id");
                pacienteId = paciente.id.toString();

                axios.post(API_URL + pacienteId + "/" + userId, {
                    mediaDerecha: parseFloat(mediaDerecha).toFixed(2),
                    mediaIzquierda: parseFloat(mediaIzquierda).toFixed(2),
                    dsDerecha: parseFloat(dsDerecha).toFixed(2),
                    dsIzquierda: parseFloat(dsIzquierda).toFixed(2)
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    setOpenGood(true);
                    window.location.href = "/home";
                }).catch(error => {
                    console.error('Error al guardar los datos:', error);
                });
            })
            .catch(error => {
                setOpenError(true);
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img src={logoUsal} alt="Logo Izquierda" style={{ height: '100px', width: 'auto', marginRight: 'auto' }} />
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}>VALORACIÓN FUNCIONAL</Typography>
                        <img src={logoCiencias} alt="Logo Derecha" style={{ height: '100px', width: 'auto', marginLeft: 'auto' }} />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <TextField
                        label="Código del Paciente"
                        required
                        variant="outlined"
                        sx={{ width: '25%', marginBottom: 4 }}
                        value={codHistorico}
                    />

                    <Typography variant="h6" sx={{ marginTop: 2 }}>DINAMÓMETRO DE LAS MANOS</Typography>
                    <Typography variant="subtitle1" sx={{ marginTop: 2 }}>Media</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Derecha(kg)" variant="outlined" value={mediaDerecha} onChange={(e) => setMediaDerecha(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Izquierda(kg)" variant="outlined" value={mediaIzquierda} onChange={(e) => setMediaIzquierda(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginTop: 2 }}>DS (Desviación Estándar)</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Derecha(kg)" variant="outlined" value={dsDerecha} onChange={(e) => setDsDerecha(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Izquierda(kg)" variant="outlined" value={dsIzquierda} onChange={(e) => setDsIzquierda(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>¡Datos guardados correctamente!</Alert>
                    </Snackbar>

                    <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                        <Alert severity="error" onClose={() => setOpenError(false)}>No hay pacientes con ese código.</Alert>
                    </Snackbar>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Button variant="contained" color="primary" onClick={guardarDatos}>
                            Guardar Datos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ValoracionFuncional;
