import React from 'react';
import { TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Snackbar, Alert } from '@mui/material';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN'); 

const API_URL = "https://" + config.BACKEND + ":3000/otros/";
const PACIENTE_URL = "https://" + config.BACKEND + ":3000/pacientes/";

const Otros = ({codigo}) => {
    const [codHistorico] = React.useState(codigo);
    const [lawtonBrody, setLawtonBrody] = React.useState('');
    const [moca, setMoca] = React.useState('');

    const [openGood, setOpenGood] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);

    const guardarDatos = () => {
        let pacienteId;
        let paciente;
        let userId;

        axios.get(PACIENTE_URL + codHistorico, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                paciente = response.data;
                userId = localStorage.getItem("id");
                pacienteId = paciente.id.toString();

                axios.post(API_URL + pacienteId + "/" + userId, {
                    lawtonBrody: parseInt(lawtonBrody),
                    moca: parseInt(moca)
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    setOpenGood(true);
                    window.location.href = "/home";
                }).catch(error => {
                    console.error('Error al guardar los datos:', error);
                });
            })
            .catch(error => {
                setOpenError(true);
                console.error('Error al obtener los datos del paciente:', error);
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: '100vh', padding: 2, backgroundColor: 'white' }}>
            <Box sx={{ maxWidth: 1500, width: '100%', padding: 2 }}>
                <AppBar position="static">
                    <Toolbar style={{ backgroundColor: 'white' }}>
                        <img src={logoUsal} alt="Logo Izquierda" style={{ height: '100px', width: 'auto', marginRight: 'auto' }} />
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black' }}>OTROS</Typography>
                        <img src={logoCiencias} alt="Logo Derecha" style={{ height: '100px', width: 'auto', marginLeft: 'auto' }} />
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: 4, maxWidth: 1000 }}>
                    <TextField
                        label="Código del Paciente"
                        required
                        variant="outlined"
                        sx={{ width: '25%', marginBottom: 4 }}
                        value={codHistorico}
                    />

                    <Typography variant="h6" sx={{ marginTop: 2 }}>VALORACIÓN COGNITIVA Y FUNCIONAL</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField width='25%' label="LAWTON Y BRODY (puntos)" variant="outlined" value={lawtonBrody} onChange={(e) => setLawtonBrody(e.target.value)} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField width='25%' label="MOCA (puntos)" variant="outlined" value={moca} onChange={(e) => setMoca(e.target.value)} />
                        </Grid>
                    </Grid>

                    <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                        <Alert severity="success" onClose={() => setOpenGood(false)}>¡Datos guardados correctamente!</Alert>
                    </Snackbar>

                    <Snackbar open={openError} autoHideDuration={2000} onClose={() => setOpenError(false)}>
                        <Alert severity="error" onClose={() => setOpenError(false)}>No hay pacientes con ese código.</Alert>
                    </Snackbar>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                        <Button variant="contained" color="primary" onClick={guardarDatos}>
                            Guardar Datos
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Otros;
