import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import config from '../../../config';
import Axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const API_URL = "https://" + config.BACKEND + ":3000/users";
const DELETE_URL = "https://" + config.BACKEND + ":3000/users/delete";
const ACTIVATE_URL = "https://" + config.BACKEND + ":3000/users/activate";

const columns = [
    { id: 'id', label: 'id', minWidth: 170 },
    { id: 'nombre', label: 'nombre', minWidth: 170 },
    { id: 'profesional', label: 'tipo de profesional', minWidth: 170 },
    { id: 'createdAt', label: 'fecha de creación', minWidth: 170, align: 'right', format: (value) => new Date(value).toLocaleDateString() },
];

function UsersTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [deletedRowIds, setDeletedRowIds] = useState([]);
    const [filterId, setFilterId] = useState('');
    const [filterNombre, setFilterNombre] = useState('');
    const [filterProfesional, setFilterProfesional] = useState('');
    const [sortDate, setSortDate] = useState(null); // null, 'asc', 'desc'
    const tableRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tableRef.current && !tableRef.current.contains(event.target) && buttonRef.current !== event.target) {
                setSelectedRow(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [tableRef, buttonRef]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getUsers = async () => {
        try {
            const response = await Axios.get(API_URL, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const users = response.data;
            const inactiveUserIds = users.filter(user => !user.active).map(user => user.id);
            setDeletedRowIds(inactiveUserIds);
            
            const mappedUsers = users.map(user => ({
                id: user.id,
                nombre: `${user.firstname} ${user.lastname}`,
                profesional: user.profesional,
                createdAt: user.createdAt,
                active: user.active
            }));
            setRows(mappedUsers);
        } catch (error) {
            console.error('Error al obtener los usuarios:', error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleRowClick = (row) => {
        setSelectedRow(row);
        console.log('Fila seleccionada:', row);
    };

    const handleToggleActive = async () => {
        let url;
        
        try {
            if(selectedRow.active === true){
                url = DELETE_URL;
            } else {
                url = ACTIVATE_URL;
            }
            await Axios.put(`${url}/${selectedRow.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setDeletedRowIds(prevDeletedRowIds => {
                if (selectedRow.active) {
                    return prevDeletedRowIds.filter(id => id !== selectedRow.id);
                } else {
                    return [...prevDeletedRowIds, selectedRow.id];
                }
            });
            setSelectedRow(null);
            getUsers(); // Actualizar usuarios después de activar o eliminar
        } catch (error) {
            console.error('Error al activar o eliminar al usuario:', error);
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'id':
                setFilterId(value);
                break;
            case 'nombre':
                setFilterNombre(value);
                break;
            case 'profesional':
                setFilterProfesional(value);
                break;
            default:
                break;
        }
    };

    const handleSortDate = () => {
        if (sortDate === 'asc') {
            setSortDate('desc');
        } else {
            setSortDate('asc');
        }
    };

    const handleResetFilters = () => {
        setFilterId('');
        setFilterNombre('');
        setFilterProfesional('');
        setSortDate(null);
    };

    const filteredRows = rows
        .filter(row => filterId === '' || row.id.toString().includes(filterId))
        .filter(row => filterNombre === '' || row.nombre.toLowerCase().includes(filterNombre.toLowerCase()))
        .filter(row => filterProfesional === '' || row.profesional.toString() === filterProfesional)
        .sort((a, b) => {
            if (sortDate === 'asc') {
                return new Date(a.createdAt) - new Date(b.createdAt);
            } else if (sortDate === 'desc') {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            return 0;
        });

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="h2" gutterBottom color='#0D6AEE'>
                    Lista de Usuarios
                </Typography>
                <Button 
                    ref={buttonRef}
                    variant="contained" 
                    sx={{
                        backgroundColor: selectedRow ? '#0D6AEE' : '#0D6AEE80',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: selectedRow ? '#0D6AEE' : '#0D6AEE80'
                        },
                        cursor: selectedRow ? 'pointer' : 'not-allowed'
                    }}
                    disabled={!selectedRow} 
                    onClick={handleToggleActive}
                >
                    {selectedRow && selectedRow.active ? 'Dar de Baja' : 'Activar'}
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <TextField
                    name="id"
                    label="Filtrar por ID"
                    value={filterId}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <TextField
                    name="nombre"
                    label="Filtrar por Nombre"
                    value={filterNombre}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <TextField
                    name="profesional"
                    label="Filtrar por Profesional"
                    value={filterProfesional}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <Button variant="contained" onClick={handleSortDate} style={{ marginRight: 16 }}>
                    Ordenar por fecha {sortDate === 'asc' ? '⬆️' : '⬇️'}
                </Button>
                <Button variant="contained" onClick={handleResetFilters}>
                    Resetear Filtros
                </Button>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} ref={tableRef}>
                <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textDecoration: 'underline', color: '#0D6AEE' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No hay usuarios registrados
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredRows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow 
                                            hover 
                                            role="checkbox" 
                                            tabIndex={-1} 
                                            key={row.id} 
                                            onClick={() => handleRowClick(row)}
                                            style={{ 
                                                cursor: 'pointer',
                                                backgroundColor: deletedRowIds.includes(row.id) ? 'lightgrey' : 'white'
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'string'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default UsersTable;
