import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, TextField, Typography, Box, Grid, AppBar, Toolbar, Button, Checkbox, FormControlLabel } from '@mui/material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logoUsal from '../../../../images/logo-usal.png';
import logoCiencias from '../../../../images/logo-usal-ciencias.png';
import axios from 'axios';
import config from '../../../../config';
import Cookies from 'universal-cookie';

const API_URL = `https://${config.BACKEND}:3000/capacidad/`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;
const DELETE_URL = `https://${config.BACKEND}:3000/capacidad/`;
const UPDATE_URL = `https://${config.BACKEND}:3000/capacidad/`;

const CapacidadFuncionalViewer = ({ idTipo, paciente }) => {
    const cookies = new Cookies();
    const token = cookies.get("TOKEN");

    const [codigo, setCodigo] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');

    // Fields for Capacidad Funcional
    const [tiempoPiesJuntos, setTiempoPiesJuntos] = useState(0.0);
    const [puntosPiesJuntos, setPuntosPiesJuntos] = useState(0);
    const [tiempoPieAdelante, setTiempoPieAdelante] = useState(0.0);
    const [puntosPieAdelante, setPuntosPieAdelante] = useState(0);
    const [tiempoPiesFila, setTiempoPiesFila] = useState(0.0);
    const [puntosPieFila, setPuntosPieFila] = useState(0);
    const [puntosEquilibrio, setPuntosEquilibrio] = useState(0);
    const [tiempoDesplazamiento, setTiempoDesplazamiento] = useState(0.0);
    const [puntosDesplazamiento, setPuntosDesplazamiento] = useState(0);
    const [tiempoPruebaFuerza, setTiempoPruebaFuerza] = useState(0.0);
    const [puntosTiempoFuerza, setPuntosTiempoFuerza] = useState(0);
    const [puntosSppb, setPuntosSppb] = useState(0);
    const [velocidad, setVelocidad] = useState(0.0);
    const [potencia, setPotencia] = useState(0.0);
    const [distancia, setDistancia] = useState(0.0);
    const [metrosAtras1, setMetrosAtras1] = useState(0.0);
    const [metrosAtras2, setMetrosAtras2] = useState(0.0);
    const [metrosAtras3, setMetrosAtras3] = useState(0.0);
    const [mediaMetrosAtras, setMediaMetrosAtras] = useState(0.0);
    const [pasosCuadrados1, setPasosCuadrados1] = useState(0.0);
    const [pasosCuadrados2, setPasosCuadrados2] = useState(0.0);
    const [pasosCuadrados3, setPasosCuadrados3] = useState(0.0);
    const [mediaPasosCuadrados, setMediaPasosCuadrados] = useState(0.0);
    const [falloEjecucion, setFalloEjecucion] = useState(false);
    const [codigoInforme, setCodigoInforme] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const [openGood, setOpenGood] = useState(false);
    const [originalValues, setOriginalValues] = useState({});

    useEffect(() => {
        if (paciente) {
            axios.get(PACIENTE_URL + paciente, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const pacienteData = response.data;
                    setCodigo(pacienteData.codHistorico);
                    setNombre(pacienteData.firstname);
                    setApellidos(pacienteData.lastname);
                })
                .catch(error => {
                    console.error('Error fetching patient data:', error);
                });

            axios.get(API_URL + idTipo, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    const capacidadFuncionalData = response.data;
                    const fecha = formatearFecha(capacidadFuncionalData.createdAt);

                    setCodigoInforme(capacidadFuncionalData.codigo);
                    setCreatedAt(fecha);

                    setTiempoPiesJuntos(capacidadFuncionalData.tiempoPiesJuntos);
                    setPuntosPiesJuntos(capacidadFuncionalData.puntosPiesJuntos);
                    setTiempoPieAdelante(capacidadFuncionalData.tiempoPieAdelante);
                    setPuntosPieAdelante(capacidadFuncionalData.puntosPieAdelante);
                    setTiempoPiesFila(capacidadFuncionalData.tiempoPiesFila);
                    setPuntosPieFila(capacidadFuncionalData.puntosPieFila);
                    setPuntosEquilibrio(capacidadFuncionalData.puntosEquilibrio);
                    setTiempoDesplazamiento(capacidadFuncionalData.tiempoDesplazamiento);
                    setPuntosDesplazamiento(capacidadFuncionalData.puntosDesplazamiento);
                    setTiempoPruebaFuerza(capacidadFuncionalData.tiempoPruebaFuerza);
                    setPuntosTiempoFuerza(capacidadFuncionalData.puntosTiempoFuerza);
                    setPuntosSppb(capacidadFuncionalData.puntosSppb);
                    setVelocidad(capacidadFuncionalData.velocidad);
                    setPotencia(capacidadFuncionalData.potencia);
                    setDistancia(capacidadFuncionalData.distancia);
                    setMetrosAtras1(capacidadFuncionalData.metrosAtras1);
                    setMetrosAtras2(capacidadFuncionalData.metrosAtras2);
                    setMetrosAtras3(capacidadFuncionalData.metrosAtras3);
                    setMediaMetrosAtras(capacidadFuncionalData.mediaMetrosAtras);
                    setPasosCuadrados1(capacidadFuncionalData.pasosCuadrados1);
                    setPasosCuadrados2(capacidadFuncionalData.pasosCuadrados2);
                    setPasosCuadrados3(capacidadFuncionalData.pasosCuadrados3);
                    setMediaPasosCuadrados(capacidadFuncionalData.mediaPasosCuadrados);
                    setFalloEjecucion(capacidadFuncionalData.falloEjecucion);
                })
                .catch(error => {
                    console.error('Error fetching valuation data:', error);
                });
        }
    }, [idTipo, paciente, token]);

    const formatearFecha = (date) => {
        const formattedDate = new Date(date);
        const day = String(formattedDate.getDate()).padStart(2, '0');
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = formattedDate.getFullYear();
        const fecha = `${day}-${month}-${year}`;

        return fecha;
    }

    const handleEdit = () => {
        setOriginalValues({
            tiempoPiesJuntos,
            puntosPiesJuntos,
            tiempoPieAdelante,
            puntosPieAdelante,
            tiempoPiesFila,
            puntosPieFila,
            puntosEquilibrio,
            tiempoDesplazamiento,
            puntosDesplazamiento,
            tiempoPruebaFuerza,
            puntosTiempoFuerza,
            puntosSppb,
            velocidad,
            potencia,
            distancia,
            metrosAtras1,
            metrosAtras2,
            metrosAtras3,
            mediaMetrosAtras,
            pasosCuadrados1,
            pasosCuadrados2,
            pasosCuadrados3,
            mediaPasosCuadrados,
            falloEjecucion,
        });
        setIsEditing(true);
    };

    const handleSave = () => {
        axios.put(UPDATE_URL + codigoInforme, {
            tiempoPiesJuntos: parseFloat(tiempoPiesJuntos),
            puntosPiesJuntos: parseInt(puntosPiesJuntos),
            tiempoPieAdelante: parseFloat(tiempoPieAdelante),
            puntosPieAdelante: parseInt(puntosPieAdelante),
            tiempoPiesFila: parseFloat(tiempoPiesFila),
            puntosPieFila: parseInt(puntosPieFila),
            puntosEquilibrio: parseInt(puntosEquilibrio),
            tiempoDesplazamiento: parseFloat(tiempoDesplazamiento),
            puntosDesplazamiento: parseInt(puntosDesplazamiento),
            tiempoPruebaFuerza: parseFloat(tiempoPruebaFuerza),
            puntosTiempoFuerza: parseInt(puntosTiempoFuerza),
            puntosSppb: parseInt(puntosSppb),
            velocidad: parseFloat(velocidad),
            potencia: parseFloat(potencia),
            distancia: parseFloat(distancia),
            metrosAtras1: parseFloat(metrosAtras1),
            metrosAtras2: parseFloat(metrosAtras2),
            metrosAtras3: parseFloat(metrosAtras3),
            mediaMetrosAtras: parseFloat(mediaMetrosAtras),
            pasosCuadrados1: parseFloat(pasosCuadrados1),
            pasosCuadrados2: parseFloat(pasosCuadrados2),
            pasosCuadrados3: parseFloat(pasosCuadrados3),
            mediaPasosCuadrados: parseFloat(mediaPasosCuadrados),
            falloEjecucion,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(() => {
                setIsEditing(false);
                setOpenGood(true);
            })
            .catch(error => {
                console.error('Error updating capacity data:', error);
            });
    };

    const handleCancel = () => {
        setTiempoPiesJuntos(originalValues.tiempoPiesJuntos);
        setPuntosPiesJuntos(originalValues.puntosPiesJuntos);
        setTiempoPieAdelante(originalValues.tiempoPieAdelante);
        setPuntosPieAdelante(originalValues.puntosPieAdelante);
        setTiempoPiesFila(originalValues.tiempoPiesFila);
        setPuntosPieFila(originalValues.puntosPieFila);
        setPuntosEquilibrio(originalValues.puntosEquilibrio);
        setTiempoDesplazamiento(originalValues.tiempoDesplazamiento);
        setPuntosDesplazamiento(originalValues.puntosDesplazamiento);
        setTiempoPruebaFuerza(originalValues.tiempoPruebaFuerza);
        setPuntosTiempoFuerza(originalValues.puntosTiempoFuerza);
        setPuntosSppb(originalValues.puntosSppb);
        setVelocidad(originalValues.velocidad);
        setPotencia(originalValues.potencia);
        setDistancia(originalValues.distancia);
        setMetrosAtras1(originalValues.metrosAtras1);
        setMetrosAtras2(originalValues.metrosAtras2);
        setMetrosAtras3(originalValues.metrosAtras3);
        setMediaMetrosAtras(originalValues.mediaMetrosAtras);
        setPasosCuadrados1(originalValues.pasosCuadrados1);
        setPasosCuadrados2(originalValues.pasosCuadrados2);
        setPasosCuadrados3(originalValues.pasosCuadrados3);
        setMediaPasosCuadrados(originalValues.mediaPasosCuadrados);
        setFalloEjecucion(originalValues.falloEjecucion);
        setIsEditing(false);
    };

    const handleExportPDF = () => {
        setIsEditing(false);
        const input1 = document.getElementById('pdf-content');
        const input2 = document.getElementById('pdf-content-2');
        html2canvas(input1, {
            scale: 2,
            backgroundColor: null,
            useCORS: true,
            ignoreElements: (element) => element.tagName === 'BUTTON',
        })
            .then(canvas1 => {
                const imgData1 = canvas1.toDataURL('image/png');
                html2canvas(input2, {
                    scale: 2,
                    backgroundColor: null,
                    useCORS: true,
                    ignoreElements: (element) => element.tagName === 'BUTTON',
                })
                    .then(canvas2 => {
                        const imgData2 = canvas2.toDataURL('image/png');
                        const pdf = new jsPDF();
                        const imgProperties1 = pdf.getImageProperties(imgData1);
                        const imgProperties2 = pdf.getImageProperties(imgData2);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight1 = (imgProperties1.height * pdfWidth) / imgProperties1.width;
                        const pdfHeight2 = (imgProperties2.height * pdfWidth) / imgProperties2.width;
                        pdf.addImage(imgData1, 'PNG', 0, 0, pdfWidth, pdfHeight1);
                        pdf.addPage();
                        pdf.addImage(imgData2, 'PNG', 0, 0, pdfWidth, pdfHeight2);
                        pdf.save(`capacidad_funcional_${nombre}${apellidos}_${createdAt}.pdf`);
                    })
                    .catch(error => {
                        console.error('Error exporting PDF:', error);
                    });
            })
            .catch(error => {
                console.error('Error exporting PDF:', error);
            });
    };


    const handleDelete = () => {
        if (window.confirm('¿Está seguro de que desea eliminar este informe?')) {
            axios.delete(DELETE_URL + codigoInforme, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log('Informe eliminado:', response.data);
                    window.location.href = "/home";
                    // Redirect or update the state as needed
                })
                .catch(error => {
                    console.error('Error deleting the report:', error);
                });
        }
    };

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: 2 }}>
            <Box sx={{ maxWidth: 1000, width: '100%', backgroundColor: 'white', padding: 2 }}>
                <Box id="pdf-content" sx={{ padding: 2 }}>
                    <AppBar position="static" sx={{ marginBottom: 4, marginTop: 8, boxShadow: 'none', border: 'none' }}>
                        <Toolbar style={{ backgroundColor: 'white' }}>
                            <img src={logoUsal} alt="Logo Izquierda" style={{ height: '70px', width: 'auto', marginRight: 'auto' }} />
                            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: 'black', fontWeight: 'bold' }}>CAPACIDAD FUNCIONAL</Typography>
                            <img src={logoCiencias} alt="Logo Derecha" style={{ height: '70px', width: 'auto', marginLeft: 'auto' }} />
                        </Toolbar>
                    </AppBar>
                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>DATOS DEL PACIENTE</Typography>
                    <Grid container spacing={2} sx={{ marginBottom: 4, marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">CÓDIGO</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={codigo}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">FECHA</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={createdAt}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">NOMBRE</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={nombre}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">APELLIDOS</Typography>
                            </Box>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={apellidos}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>SPPB</Typography>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Prueba de Equilibrio</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO PIES JUNTOS (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={tiempoPiesJuntos}
                                onChange={handleInputChange(setTiempoPiesJuntos)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PUNTOS PIES JUNTOS</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={puntosPiesJuntos}
                                onChange={handleInputChange(setPuntosPiesJuntos)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO PIE ADELANTE (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={tiempoPieAdelante}
                                onChange={handleInputChange(setTiempoPieAdelante)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PUNTOS PIE ADELANTE</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={puntosPieAdelante}
                                onChange={handleInputChange(setPuntosPieAdelante)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO PIES EN FILA (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={tiempoPiesFila}
                                onChange={handleInputChange(setTiempoPiesFila)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PUNTOS PIES EN FILA</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={puntosPieFila}
                                onChange={handleInputChange(setPuntosPieFila)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Prueba de Desplazamiento</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO PRUEBA DESPLAZAMIENTO (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={tiempoDesplazamiento}
                                onChange={handleInputChange(setTiempoDesplazamiento)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PUNTOS PRUEBA DESPLAZAMIENTO</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={puntosDesplazamiento}
                                onChange={handleInputChange(setPuntosDesplazamiento)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Prueba de Fuerza EE. II.</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">TIEMPO PRUEBA DE FUERZA (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={tiempoPruebaFuerza}
                                onChange={handleInputChange(setTiempoPruebaFuerza)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PUNTOS PRUEBA DE FUERZA</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={puntosTiempoFuerza}
                                onChange={handleInputChange(setPuntosTiempoFuerza)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="subtitle1" sx={{ marginBottom: 2, textAlign: 'left' }}>Puntos SPPB</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={6}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">PUNTOS TOTALES</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={puntosSppb}
                                onChange={handleInputChange(setPuntosSppb)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box id="pdf-content-2" sx={{ padding: 2 }}>

                    <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 8, textAlign: 'center' }}>ENCODER</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">VELOCIDAD (m/s)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={velocidad}
                                onChange={handleInputChange(setVelocidad)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">POTENCIA (w)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={potencia}
                                onChange={handleInputChange(setPotencia)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">DISTANCIA (cm)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={distancia}
                                onChange={handleInputChange(setDistancia)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>3 METROS MARCHA ATRÁS</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 1 (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={metrosAtras1}
                                onChange={handleInputChange(setMetrosAtras1)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 2 (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={metrosAtras2}
                                onChange={handleInputChange(setMetrosAtras2)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 3 (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={metrosAtras3}
                                onChange={handleInputChange(setMetrosAtras3)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIA TOTAL (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={mediaMetrosAtras}
                                onChange={handleInputChange(setMediaMetrosAtras)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>4 PASOS CUADRADOS</Typography>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 1 (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={pasosCuadrados1}
                                onChange={handleInputChange(setPasosCuadrados1)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 2 (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={pasosCuadrados2}
                                onChange={handleInputChange(setPasosCuadrados2)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIDA 3 (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={pasosCuadrados3}
                                onChange={handleInputChange(setPasosCuadrados3)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                        <Grid item xs={4}>
                            <Box sx={{ padding: 0.5, backgroundColor: '#757575' }}>
                                <Typography variant="subtitle2" color="white">MEDIA TOTAL (segundos)</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={mediaPasosCuadrados}
                                onChange={handleInputChange(setMediaPasosCuadrados)}
                                InputProps={{ readOnly: !isEditing }}
                                sx={{
                                    border: isEditing ? '1px solid #ccc' : 'none',
                                    fontSize: 'small',
                                    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={falloEjecucion} disabled={!isEditing} onChange={(e) => setFalloEjecucion(e.target.checked)} />}
                                label="Fallo en ejecución"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    {isEditing ? (
                        <>
                            <Button variant="contained" color="primary" onClick={handleSave} sx={{ marginRight: 2 }}>Guardar Cambios</Button>
                            <Button variant="contained" color="error" onClick={handleDelete} sx={{ marginRight: 2 }}>Eliminar</Button>
                            <Button variant="contained" onClick={handleCancel}>Cancelar edición</Button>
                        </>
                    ) : (
                        <>
                            <Button variant="contained" color="primary" onClick={handleEdit} sx={{ marginRight: 2 }}>Editar</Button>
                            <Button variant="contained" color="secondary" onClick={handleExportPDF} sx={{ marginRight: 2 }}>Exportar PDF</Button>
                            <Button variant="contained" color="error" onClick={handleDelete}>Eliminar</Button>
                        </>
                    )}
                </Box>
                <Snackbar open={openGood} autoHideDuration={2000} onClose={() => setOpenGood(false)}>
                    <Alert severity="success" onClose={() => setOpenGood(false)}>¡Informe actualizado!</Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default CapacidadFuncionalViewer;
