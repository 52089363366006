import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import config from '../../../../../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('TOKEN');

const ENTREVISTAS_URL = `https://${config.BACKEND}:3000/entrevistas`;
const PACIENTE_URL = `https://${config.BACKEND}:3000/pacientes/informes/`;

const columns = [
    { id: 'paciente', label: 'nombre del paciente', minWidth: 170 },
    { id: 'fecha', label: 'fecha (dd-mm-yyyy)', minWidth: 100 },
    {
        id: 'venredi',
        label: 'venredi',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'terapiaOcupacional',
        label: 'terapia ocupacional',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'actions',
        label: '',
        minWidth: 50,
        align: 'right',
    }
];

function EntrevistasTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [entrevistas, setEntrevistas] = useState([]);
    const [filterPaciente, setFilterPaciente] = useState('');
    const [sortDate, setSortDate] = useState(null); // null, 'asc', 'desc'
    const [filterVenredi, setFilterVenredi] = useState('');
    const [filterTO, setFilterTO] = useState('');

    useEffect(() => {
        axios.get(ENTREVISTAS_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const data = response.data;
                formatData(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const formatData = (data) => {
        const formattedData = data.map(entrevista => {
            let venredi;
            let to;

            const formattedDate = new Date(entrevista.createdAt);
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const year = formattedDate.getFullYear();
            const fecha = `${day}-${month}-${year}`;

            if (entrevista.venredi) {
                venredi = "Pertenece";
            } else {
                venredi = "No pertenece";
            }

            if (entrevista.terapiaOcupacional) {
                to = "Pertenece";
            } else {
                to = "No pertenece";
            }

            return {
                id: entrevista.codigo,
                pacienteId: entrevista.pacienteId,
                venredi: venredi,
                terapiaOcupacional: to,
                fecha: fecha,
                fechaSort: formattedDate
            };
        });

        Promise.all(formattedData.map(entrevista => axios.get(PACIENTE_URL + entrevista.pacienteId, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })))
            .then(responses => {
                formattedData.forEach((entrevista, index) => {
                    const paciente = responses[index].data;
                    entrevista.paciente = `${paciente.firstname} ${paciente.lastname}`;
                });

                setEntrevistas(formattedData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSortDate = () => {
        if (sortDate === 'asc') {
            setSortDate('desc');
        } else {
            setSortDate('asc');
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'paciente':
                setFilterPaciente(value);
                break;
            case 'venredi':
                setFilterVenredi(value);
                break;
            case 'terapiaOcupacional':
                setFilterTO(value);
                break;
            default:
                break;
        }
    };

    const handleResetFilters = () => {
        setFilterPaciente('');
        setSortDate(null);
        setFilterVenredi('');
        setFilterTO('');
    };

    const filteredEntrevistas = entrevistas
        .filter(entrevista => filterPaciente === '' || entrevista.paciente.toLowerCase().includes(filterPaciente.toLowerCase()))
        .filter(entrevista => filterVenredi === '' || entrevista.venredi === filterVenredi)
        .filter(entrevista => filterTO === '' || entrevista.terapiaOcupacional === filterTO)
        .sort((a, b) => {
            if (sortDate === 'asc') {
                return a.fechaSort - b.fechaSort;
            } else if (sortDate === 'desc') {
                return b.fechaSort - a.fechaSort;
            }
            return 0;
        });

    return (
        <>
            <Typography variant="h6" component="h2" gutterBottom color='#0D6AEE'>
                Lista de Entrevistas
            </Typography>
            <div>
                <TextField
                    name="paciente"
                    label="Filtrar por Paciente"
                    value={filterPaciente}
                    onChange={handleFilterChange}
                    style={{ marginRight: 16 }}
                />
                <TextField
                    select
                    name="venredi"
                    label="Filtrar por Venredi"
                    value={filterVenredi}
                    onChange={handleFilterChange}
                    SelectProps={{
                        native: true,
                    }}
                    style={{ marginRight: 16 }}
                >
                    <option value=''></option>
                    <option value='Pertenece'>Pertenece</option>
                    <option value='No pertenece'>No pertenece</option>
                </TextField>
                <TextField
                    select
                    name="terapiaOcupacional"
                    label="Filtrar por Terapia Ocupacional"
                    value={filterTO}
                    onChange={handleFilterChange}
                    SelectProps={{
                        native: true,
                    }}
                    style={{ marginRight: 16 }}
                >
                    <option value=''></option>
                    <option value='Pertenece'>Pertenece</option>
                    <option value='No pertenece'>No pertenece</option>
                </TextField>
                <Button variant="contained" onClick={handleSortDate} style={{ marginRight: 16 }}>
                    Ordenar por fecha {sortDate === 'asc' ? '⬆️' : '⬇️'}
                </Button>
                <Button variant="contained" onClick={handleResetFilters}>
                    Resetear Filtros
                </Button>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, textDecoration: 'underline', color: '#0D6AEE' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredEntrevistas.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No hay entrevistas registradas
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredEntrevistas
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell align="right">
                                                <IconButton color="primary" onClick={() => { window.location.href = `/ver-Entrevista/${row.id}/${row.pacienteId}`; }}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredEntrevistas.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export default EntrevistasTable;
