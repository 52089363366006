import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BalanceIcon from '@mui/icons-material/Balance';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import StraightenIcon from '@mui/icons-material/Straighten';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const ListItemWithNavigation = ({ icon: Icon, text, to }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export const mainListItems = (
  <React.Fragment>
    <ListItemWithNavigation icon={DashboardIcon} text="Home" to="/home" />
    <ListItemWithNavigation icon={PersonAddIcon} text="Crear Paciente" to="/crear-paciente-user" />
    <ListItemWithNavigation icon={AssignmentIcon} text="Entrevista" to="/lista-Entrevistas" />
    <ListItemWithNavigation icon={BalanceIcon} text="Capacidad Funcional" to="/lista-Capacidad-Funcional" />
    <ListItemWithNavigation icon={FitnessCenterIcon} text="Antropometría" to="/lista-Antropometria" />
    <ListItemWithNavigation icon={BloodtypeIcon} text="Tensión Arterial" to="/lista-Tension-Arterial" />
    <ListItemWithNavigation icon={StraightenIcon} text="Valoración Funcional" to="/lista-Valoracion-Funcional" />
    <ListItemWithNavigation icon={LayersIcon} text="Otros" to="/lista-Otros" />
  </React.Fragment>
);
