// MainInterface.js
import React from 'react';
import { Container, Grid, Box } from '@mui/material';
import Login from './Login';
import logoSocioSalud from '../../images/logo-sociosalud.png';
import logoUsal from '../../images/logo-usal.png';
import logoCiencias from '../../images/logo-usal-ciencias.png';
import background from '../../images/background.jpeg'

const MainRegister = () => {
    const imgStyle = { maxWidth: '150px', height: 'auto', margin: '5px' };
    const boxStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '175px', width: '500px', margin: 'auto' }

    return (
        <div
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', // Asegura que la imagen cubra todo el contenedor
                backgroundPosition: 'center', // Centra la imagen en el contenedor
                backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
                width: '100%',
                height: '100vh', // Ajusta la altura según tus necesidades
            }}
        >
            <Container style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Grid container style={{ flexGrow: 1 }} alignItems="center" justifyContent="center">
                <Grid item>
                    <Login />
                </Grid>
            </Grid>

            <Box style={boxStyle}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={4} style={{ textAlign: 'left' }}>
                        <img src={logoCiencias} alt="Facultad de Ciencias" style={imgStyle} />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <img src={logoUsal} alt="SocioSalud" style={imgStyle} />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <img src={logoSocioSalud} alt="SocioSalud" style={imgStyle} />
                    </Grid>
                </Grid>
            </Box>
        </Container>
        </div>
        
    );
}

export default MainRegister;
